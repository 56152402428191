/* HighlightDataSection.css */
.outter-section {
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
  border: 1px solid #e6e7eb;
  padding-bottom: .325rem;
  margin-top: 20px;
  transition: all .15s ease-in-out;
  position: relative;
  margin-top: 5%;
}

.edit-highlight-data-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: White;
  transition: all .15s ease-in-out;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
}

.highlight-data-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: White;
  transition: all .15s ease-in-out;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 1rem;
  min-height: 300px;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
  -webkit-box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
}

.edit-highlight {
  margin-bottom: 2%;
  flex-wrap: wrap;
}

.highlight-media {
  margin-right: 3%;
  /* Adjust spacing between media and details */
  max-width: 60%;
}

.highlight-details {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 35% ;
}

.highlight-media video,
.highlight-media img {
  max-width: 100%;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;
  object-position: 45% 0%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f1f1f1;
}

@media screen and (max-width: 600px) {
  .highlight-data-section {
    display: block;
    padding: 10px;
  }

  .highlight-media {
    max-width: 100%;
  }

  .highlight-shop-button {
    margin-top: 1px !important;
    max-width: none !important;
  }

  .highlight-name {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .highlight-details {
    display: flex;
    flex-direction: column;
    margin: 0 auto !important;
    width: 100% ;
  }

  .highlight-media img,
  .highlight-media video {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
    object-position: 45% 0%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f1f1f1;
    max-height: 450px;
  }
}

.highlight-link-tag {
  color: #0e0e0e !important;
  height: 100%;
  position: relative;
  text-decoration: none;
  transition: all .15s ease-in-out;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
  display: contents;
}

.highlight-shop-button:hover {
  background: #d0d2d5 !important;
  color: black;
}

.highlight-shop-button {
  background: black;
  color: white;
  border: none;
  max-width: 300px;
  width: 100%;
  min-width: 60px;
  height: 100%;
  white-space: normal;
  word-break: break-word;
  min-width: 60px;
}

.highlight-name {
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 10px;
  white-space: normal;
  word-break: break-word;
  padding: 1%;
}

.text-decoration-none {
  text-decoration: none;
}
.link-presentation-highlight{
  display: flex;
  width: 100%;
  gap: 20%;
  margin-left: 2px;
}

.links-view-icon {
  height: 38px;
  width: 38px;
}

.link-presentation-highlight span {
  padding: 4px;
}

.link-presentation-highlight span.active {
  border: 1px solid;
  padding: 4px;
  border-radius: 4px;
  color: #000;
}

.right-view-icon{
  transform: rotate(180deg);
}

@media (max-width: 450px) {
  .highlight-data-section{
    min-height: 100%;
  }
}