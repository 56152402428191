.skeleton-container {
    width: 100%;
    padding: 16px;
  }
  
  .skeleton-container.grid-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
  
  @media screen and (max-width: 1099px) {
    .skeleton-container.grid-layout {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 768px) {
    .skeleton-container.grid-layout {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .skeleton-container.line-layout {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .grid-skeleton-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .grid-skeleton-image {
    margin-bottom: 16px;
  }
  
  .grid-skeleton-content {
    padding: 8px 0;
  }
  
  .line-skeleton-card {
    background: white;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .line-skeleton-content {
    flex: 1;
  }