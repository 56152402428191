.analytics-tables {
	max-width: 90%;
	margin-top: 10px;
	font-size: 1rem;
}

.analytics-list {
	font-size: 1rem;
	line-height: normal;
	margin-bottom: 5%;
	margin-left: -12px;
	margin-top: 18px;
}
.analytics-postClicks-thumbnail-div {
	width: 100%;
}
.analytics-postClicks-thumbnail {
	/* max-width: 50%; */
	max-width: 25%;
    vertical-align: unset;
}

.search-form {
	font-size: 20px;
	margin: 25px 0 15px 0;
}

@media only screen and (max-width: 650px) {
	.tool-tip{
		font-size: 0.6rem;
		/* width:80vw; */
		word-wrap: break-word;
	}
}

/*  */