.outer-container-podcast {
  /* width: 62%; Set the width to 60% */
  margin: 0 auto;
  display: flex;
  justify-content: justify;
  overflow-x: hidden;

  /* overflow:hidden */
  /* border:1px solid red Allow horizontal scrolling */
}

.links-grid-container-podcast {
  display: flex; /* Arrange children in a row */
  flex-wrap: nowrap; /* Prevent wrapping to next line */
margin-left: 0px;
}
.creator-feed-image-wrapper-pod{
  width: 87%;
  object-fit: cover;
  border-radius: 1rem;
  transition: all .15s ease-in-out;
  position: relative;
  box-shadow:0 2px 0.75rem -0.25rem rgba(10,20,26,.08);
  border-radius: 1rem;
  padding-bottom: .325rem;
}
.creator-feed-image-wrapper-podcast {
  /* border:1px solid red; */
  flex: 0 0 auto;
  width: 200px;
  height: 300px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 15px;
  background-color: #f1f1f1;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.creator-feed-image-wrapper-podcast:hover {
  background-color: #d0d2d5;
}

.creator-feed-card-podcast-image {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 15px;
  background-color: #f1f1f1;
}

.template-shop-btn-podcast{
  /* border:1px solid purple; */
  width:200px;
  background-color: black;
  color: white;
  border:none;
  margin-top: 1%;

}
.template-shop-btn-podcast:hover{
  /* border:1px solid purple; */
  width:200px;
  background-color: grey;
  color: white;
  border:none;
  margin-top: 1%;
}

/* TiktokData.css */
.creator-feed-card-container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-left: 0%;
}

.creator-feed-card-podcast.not-available {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* .creator-feed-image-wrapper-podcast {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
} */

/* .creator-feed-card-podcast-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
} */

.caption {
  color: #333;
  font-size: 1rem;
  word-wrap: break-word;
  width: 100%;
  padding: 10%;
  box-sizing: border-box;
  text-decoration: none;
  overflow: hidden;
  white-space: normal;
  height: 75px;
  font-family: inherit;
}

.podcast-link-tag{
  text-decoration: none;

}