.header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-logo-tittle {
  /* color: #000;
	font-size: 2rem;
	font-weight: bold;
	margin: 0; */
  margin-top: auto;
  margin-bottom: auto;
}
.header-nav-title {
  color: #000;
  font-size: 2em;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
}
.header-nav-title a {
  background-color: inherit;
  text-decoration: none;
  color: #ededed;
}

.header-nav-title a:hover {
  color: #ededed;
}
.header-nav-image {
  /* align-items: center;
	border-radius: 99px;
	border-radius: 99px;

box-shadow:0 0 8px 5px #00000000;
color: #404040;
cursor: pointer;
display: flex;
font-size: 22px;
gap: 10px; justify-content: space-around;
padding: 5px 5px 5px 5px;
position : relative; width: 40px;
background-color: #eff0ec; */
  position: relative;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 5px 14px;
  gap: 10px;
  font-size: 22px;
  -webkit-box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: #404040;
}

.header-nav-image-live {
  /* align-items: center;
	border-radius: 99px;
	border-radius: 99px;

box-shadow:0 0 8px 5px #00000000;
color: #404040;
cursor: pointer;
display: flex;
font-size: 22px;
gap: 10px; justify-content: space-around;
padding: 5px 5px 5px 5px;
position : relative; width: 40px;
background-color: #eff0ec; */
  position: relative;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 5px 5px 14px;
  gap: 10px;
  font-size: 22px;
  width: 30px;
  -webkit-box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
  box-shadow:0 0 8px 5px #00000000;
  cursor: pointer;
  color: #404040;
  background-color: #eff0ec; 
  /* padding: 6px 5px 5px 5px; */
  padding: 4px;

}

.header-dashboard-img {
	height: 38px;
	width: 38px;
	border-radius: 50%;
	object-fit: contain;
}
.header-dashboard-menu-item {
  margin: 0;
}
.nav-menu {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  position: fixed;

  right: -100%;
  transition: 850ms;
  z-index: 1;
}
.nav-menu.active {
  transition: 350ms;
  right: 50px;
  position: absolute;
}
.nav-menu-items {
  width: 100%;
  display: grid;
  margin-top: 8px;
  padding-left: 0px;
}

.nav-text {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #fff;
  list-style: none;
  padding: 5px;
  justify-content: center;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}
.sidebar {
  background-color: #404040;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: max-content;
  padding: 5px 5px;
  font-size: 1rem;
}
.sidebar:hover {
  background-color: #f1f1f1;
  color: #404040;
}
.live-menu-wrapper {
  top: 0;
  margin-left: auto;
}
.ant-message-notice .ant-message-notice-content {
background-color: grey;
color:white
}
.copy-link-msg{
  width:100%
}
.dashboard-menu-font {
  font-size: 14px;
  font-weight: bold;
}
.font-16 {
  font-size: 16px;
}

.sign-up-btn {
  background: black;
  color: white;
  font-size: 14px;
  margin-bottom: 2%;
  margin-bottom: 5px;
  width: 100%;
}

.three-dots {
  margin-top: -2%;
  width: 75%;
}

.header-menu {
  font-size: 14px;
  margin-bottom: 1%;
  font-weight: bold;
}

.sign-up-btn-bg {
  color: white;
  font-size: 14px;
  margin-bottom: 2%;
  width: 100%;
}

.text-right {
  text-align: right;
}

.falock-color {
  color: grey;
}

.falock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.platform-name {
  color: #414141;
  text-align: center;
}

.confirm-btn-color {
  background-color: #404040;
  color: #fff;
}

.hr-style {
  opacity: 0.1;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.unlock-text {
  margin-top: 2%;
  font-size: 1rem;
}
.custom-dropdown-menu .ant-dropdown-menu-title-content{
  display: inline-block !important;
}