.outer-container-subcomponents {
  /* width: 95%; 
  margin: 0 auto;
  display: flex;
  overflow-x: auto; 
  padding: 10px 0;
  margin-top: 2%; */

  margin: 2% auto 0;
  overflow-x: auto;
  padding: 10px 0;
  width: 95%;
  overflow-y: hidden;
}

.creator-feed-image-wrapper-links-subcomponents h6 {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.links-grid-container-subcomponents-pod {
  display: flex;
  /* Arrange children in a row */
  flex-wrap: nowrap;
  /* Prevent wrapping to next line */
  margin-left: 0px;
  gap: 15px;
}

.links-grid-container-subcomponents-pod a {
  text-decoration: none;
}

.links-grid-container-subcomponents {
  display: flex;
  flex-wrap: nowrap;
}
/* @media (max-width: 661px) {
  .links-grid-container-subcomponents {
      width: 190px !important;
      max-width: 100%;
      display: block;
  }
} */
.links-grid-container-subcomponents a {
  color: black;
  text-decoration: none;
}

.creator-feed-image-wrapper-links-subcomponents {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 180px;
}

.creator-feed-card-links-image-subcomponents video {
  height: 280px;
  object-fit: cover;
  border-radius: 1rem;
  padding-bottom: 0rem;
}

.creator-feed-image-wrapper-links-subcomponents .ant-carousel .slick-dots-bottom {
  bottom: -1.2%;
}

.ant-carousel .slick-dots li button::after {
  display: none;
}

/* .slick-dots {
    top: 330px;
  } */

/* .slick-dots .slick-active:last-child {
  display: none;
} */

.creator-feed-image-wrapper-links .ant-carousel .slick-dots li button {
  background: #ffffff;
}

.creator-feed-card-links-image-subcomponents {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  border: 1px solid #e6e7eb;
  padding-bottom: 0.325rem;
  transition: all 0.15s ease-in-out;
  position: relative;
}

.creator-feed-card-links-image-subcomponents .hover-play {
  height: 280px;
}

.template-shop-btn-links-subcomponents {
  width: 180px;
  background-color: #e6e7eb;
  color: #000;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.template-shop-btn-links-subcomponents:hover {
  box-shadow: 0 0.031rem 0.125rem 0 rgba(10, 20, 26, 0.08);

  width: 180px;
  background-color: #d0d2d5 !important;
  background: #d0d2d5 !important;
}

.outter-section-banner-subcomponent {
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  border: 1px solid #e6e7eb;
  padding-bottom: 0.325rem;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.15s ease-in-out;
  position: relative;
  width: 95%;
  overflow: hidden;
  /* Ensure the image doesn't overflow the div */
  height: auto;
  margin-top: 2%;
}

@media screen and (max-width: 600px) {
  .outter-section-banner-subcomponent {
    height: auto;
    width: 100%;
  }

  .banner-image-subcomponent {
    height: 195px;
  }

  .banner-title-subcomponent {
    left: 35% !important;
    font-size: 0.8rem !important;
    margin-top: -10.5% !important;
  }
}

.banner-image-subcomponent img {
  height: 99%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: 0% 5%;
  width: 100%;

  /* width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem; /* Same as the parent to keep the rounded corners */
  /* display: block; /* Ensure the image is a block-level element */
  /* object-position: 0% 1%; */
}

/* .banner-image-subcomponent {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem; /* Same as the parent to keep the rounded corners */
/* display: block; Ensure the image is a block-level element */
/* object-position: 0% 1%; */
/* } */

.outter-section-subcomponent {
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  border: 1px solid #e6e7eb;
  padding-bottom: 0.325rem;
  margin-top: 20px;
  transition: all 0.15s ease-in-out;
  position: relative;
  /* margin-top: 5%; */
}

.outter-section-subcomponent a {
  text-decoration: none;
}

.outter-section-div {
  display: block;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: White;
  transition: all 0.15s ease-in-out;
  border: 1px solid #e6e7eb;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  color: black;
}

.outter-section-div div {
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding-top: 0px;
  padding-bottom: 10px;
}

.highlight-data-section-subcomponent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: White;
  transition: all 0.15s ease-in-out;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  min-height: 300px;
}

.highlight-media-subcomponent {
  margin-right: 5px;
  max-width: 60%;
  /* Adjust spacing between media and details */
}

.highlight-details-subcomponent {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 40%;
}

.highlight-media-subcomponent video,
.highlight-media-subcomponent img {
  max-width: 100%;
  max-height: 300px;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;
  object-position: 45% 0%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f1f1f1;
}

.highlight-name-subcomponent {
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 10px;
  white-space: normal;
  word-break: break-word;
  padding: 1%;
}

.highlight-link-tag-subcomponent {
  color: #0e0e0e !important;
  height: 100%;
  position: relative;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  display: contents;
}

/* .highlight-link-tag-subcomponent:hover {
    text-decoration: none;
    border: 1px solid #f1f1f1;
  } */
/* .highlight-link-tag .highlight-name{
    color:black;
    text-decoration: none;
  } */
.highlight-shop-button-subcomponent:hover {
  background: #d0d2d5 !important;
  color: black;
}

.highlight-shop-button-subcomponent {
  /* margin-top: 10px; */
  background: black;
  color: white;
  border: none;
  max-width: 300px;
  width: 100%;
  white-space: normal;
  word-break: break-word;
}

/* . */
.creator-feed-image-wrapper-pod-subcomponents {
  /* width: 87%; */
  object-fit: cover;
  border-radius: 1rem;
  transition: all 0.15s ease-in-out;
  position: relative;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  border-radius: 1rem;
  padding-bottom: 0.325rem;
}

.creator-feed-image-wrapper-podcast-subcomponents {
  /* border:1px solid red; */
  flex: 0 0 auto;
  width: 200px;
  height: 300px;
  /* margin-right: 30px; */
  cursor: pointer;
  border-radius: 15px;
  background-color: #f1f1f1;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.creator-feed-image-wrapper-podcast-subcomponents:hover {
  background-color: #d0d2d5;
}



.card-link-subcomponents {
  text-decoration: none;
  /* Remove default anchor tag underline */
  color: inherit;
  /* Inherit text color */
}

.creator-card-grid-wrp-subcomponents {
  display: grid;
  justify-content: center;
  grid-gap: 25px;
  /* margin-bottom: 2rem; */
  margin-top: 1rem;
  overflow: hidden;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1280px) {
  .creator-card-grid-wrp-subcomponents {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .creator-card-grid-wrp-subcomponents {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .highlight-shop-button-subcomponent {
    max-width: none !important;
  }

  .highlight-details-subcomponent {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }

  .highlight-media-subcomponent {
    max-width: 100%;
  }

  .highlight-media-subcomponent img,
  .highlight-media-subcomponent video {
    width: 100%;
    background-color: #f1f1f1;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    max-height: 300px;
    height: 300px;
    object-fit: cover;
    object-position: 45% 0;
  }

  .highlight-name-subcomponent {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.creator-card-subcomponents {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  height: fit-content;
  /* width: 100%; */
  /* border-radius: 1rem;
    box-shadow:0 2px 0.75rem -0.25rem rgba(10,20,26,.08);
    border: 1px solid #e6e7eb; */
  padding-bottom: 0.325rem;
}

.creator-card-meta-subcomponents:hover {
  background-color: #d0d2d5;
  border-radius: 8px;
  overflow: hidden;
  font-weight: bolder;
}

.creator-card-meta-subcomponents {
  white-space: normal;
  overflow-wrap: break-word;
  padding: 10px;
  font-size: 16px;
  color: #000;
  background-color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 5.25rem;
  padding: 1rem 0.75rem 0.5rem;
  font-weight: bolder;
}

.creator-card-cover-subcomponents {
  position: relative;
}

.creator-card-img-subcomponents {
  display: block;
  height: 100%;
  left: 0;
  /* opacity: 0.5; */
  position: absolute;
  top: 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
}

.creator-card-img-subcomponents {
  width: 100%;
  height: 8rem;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
  height: 8rem;
  width: 100%;
  object-fit: cover;
  object-position: 5% 14%;
}

.creator-card-dummy-img-subcomponents {
  width: 100%;
  height: 8rem;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
}

.highlight-data-section-subcomponent button {
  white-space: break-spaces;
}

@media screen and (max-width: 600px) {
  .highlight-data-section-subcomponent {
    display: block;
    padding: 10px;
  }

  .highlight-media {
    margin-right: 0% !important;
  }

  .highlight-shop-button {
    margin-top: 1px !important;
  }

  .highlight-name {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.skeleton-stat-box {
  margin-top: 2%;
  width: 100%;
  height: 120px;
}

.edit-btn {
  background-color: black;
  color: white;
  font-size: 0.6rem;
}

.remove-btn {
  background-color: rgb(241, 98, 98);
  color: white;
  font-size: 0.6rem;
}

.item-description {
  font-weight: bold;
  font-size: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.shop-image {
  width: 100%;
  margin: 0 auto;
  border-radius: 1rem;
}

.stayUpToDate-skeleton {
  width: 90%;
  height: 300px;
  cursor: pointer;
  border-radius: 15px;
  margin-bottom: 4%;
}

.remove-product {
  text-align: center;
  padding: 20px;
  padding-top: 40px;
  font-weight: bold;
  font-size: 16px;
}

.p-20 {
  padding: 20px;
}

.h-100 {
  height: 100%;
}

.mw-300 {
  max-width: 300px;
}

.mh-300 {
  max-height: 300px;
}

.pb-10px {
  padding-bottom: 10px;
}

.text-decoration-none {
  text-decoration: none;
}

.index1 {
  z-index: 1;
}

.mt-1 {
  margin-top: 1%;
}

.mt-7 {
  margin-top: 7.5%;
}

.custom-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.active-tab {
  margin: 0;
  font-weight: bold;
}

.skeleton-loading {
  margin-bottom: 4%;
  margin-right: 2%;
  width: 150px;
  height: 250px;
}

.stat-box {
  display: flex;
  flex-direction: row;
}

.banner-sketeton {
  margin: 0 auto;
  margin-top: 2%;
  width: 70%;
  height: 220px;
}

@media (max-width: 450px) {

  .highlight-media-subcomponent img,
  .highlight-media-subcomponent video {
    width: 100%;
    background-color: #f1f1f1;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    min-height: 176px;
    height: 100%;
    max-height: 100%;
    object-position: 45% 0;
  }
}