.account-description-wrapper{
    margin-bottom: 4%;
}
.btn-primary {
    background-color: black;
    color: white;
    font-size: 0.8rem;
    margin-left: 1%;
}
.ml-1{
    margin-left: 1%;
}
.spin-style {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}
.textarea {
    width: 96%;
    margin: auto;
}
.social-content-color {
    background-color: #fff;
    border: solid #fff 1px;
}
.hr-style {
opacity: 0.1;
max-width: 100%;
width: 100%;
margin: 0;
}