.links-container {
  display: flex ;
  flex-wrap: wrap ;
  gap: 16px ;
  position: relative ;
  /* padding: 10px; */
  width: 100% ;
  /* or the desired width */
  padding-top: 2% ;
}

.links-container-frontend {
  display: flex ;
  flex-wrap: wrap ;
  gap: 25px ;
  position: relative ;
  justify-content: center ;
  /* padding: 2px; */
}

.grid-layout {
  justify-content: flex-start ;
  align-items: flex-start ;
}

.line-layout {
  /* flex-shrink: 0; prevent shrinking */
  /* width: 100%; ensure full width */
  /* min-width: 100%; Ensure it occupies the full width */

  flex-direction: column ;
  align-items: center ;
}

.add-button-grid {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button-grid:hover {
  background-color: white;
  color: black;
  border: 1px solid black
}

.add-button-line-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  /* Center the button horizontally */
}

.add-button-line {
  width: 48px ;
  height: 48px ;
  font-size: 24px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 16px;
  margin-bottom: 6px ;
  display: flex ;
  align-items: center ;
  justify-content: center ;
}

.add-button-line:hover {
  background-color: white;
  color: black;
  border: 1px solid black
}

/* .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
} */

.creator-card-grid-wrp-subcomponents {
  min-height: 200px;
  position: relative;
  width: 100%;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
  gap: 1rem;
  padding: 0 10px; /* Add padding for mobile */
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.grid-item.dragging {
  width: 200px; /* Match your grid item width */
  height: auto;
  background: white;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  z-index: 9999;
}

/* Ensure the GridTemplate maintains its dimensions */
.grid-item.dragging > * {
  width: 100%;
  height: 100%;
}

.creator-card-grid-wrp-subcomponents > * {
  transition: all 0.3s ease;
  transform-origin: center;
}

.creator-card-grid-wrp-subcomponents > *[data-rbd-dragging="true"] {
  z-index: 9999;
  cursor: grabbing;
  opacity: 0.9;
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Add hover state for draggable items */
.creator-card-grid-wrp-subcomponents > *:hover {
  cursor: grab;
}

/* Style while actively dragging */
.creator-card-grid-wrp-subcomponents > *:active {
  cursor: grabbing;
}

/* Add animation for items shifting position */
.creator-card-grid-wrp-subcomponents > *:not([data-rbd-dragging="true"]) {
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .creator-card-grid-wrp-subcomponents {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)) !important;
    gap: 0.5rem; /* Reduce gap on mobile */
    padding: 0 5px; /* Adjust padding for mobile */
  }

  .grid-item.dragging {
    width: 120px; /* Adjust dragging width for mobile */
  }
}