.newsletter-post-img-wrp {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}
.newsletter-post-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: #1a1a16;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsletter-post-playbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: rgba(255, 255, 255, 0.8);
  font-size: 5rem;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-post-playbtn:hover {
  color: rgba(255, 255, 255, 1);
}
.newsletter-post-img-date {
  color: rgb(67, 66, 66);
  font-size: 1.5rem;
}
.newsletter-post-img-date p {
  text-align: center;
}
.newsletter-post-btn {
  border: none;
  /* background-color: #fff; */
  color: black;
  background-color: lightgrey;
  font-size: 14px;
  border-radius: 5px;
}
.newsletter-post-btn:hover {
  border: none;
  background-color: #414141;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.newsletter-post-img-date {
  text-align: center;
}

.newsletter-label {
  display: block;
  /* background-color: black; */
  color: white;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.041em;
  text-align: center;
  white-space: normal;
  word-break: break-word;
}

.date {
  display: block;
  color: white;
  font-size: 15px;
  font-family: 'IBM Plex Sans', sans-serif;
  /* font-family: 'Inter', sans-serif; */
  font-weight: bold;
  letter-spacing: 0.041em;
}
@media screen and (max-width: 600px){
  .newsletter-post-img-wrp {
    height: 305px;
}

}