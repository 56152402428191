.date-selector-btn {
	background: white;
	border: solid black 1px;
	border-radius: 9px;
	padding: 4px 12px;
	cursor: pointer;
	white-space: nowrap;
	font-size: .9rem;
}
.date-selector-btn:hover {
	background: rgb(243, 243, 243);
}
.date-selector-btn.active {
	background: #3b3b3b;
	color: white;
}
.date-selector {
	display: flex;
	gap: 10px;
	overflow-x: scroll;
}
