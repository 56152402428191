.outer-container-tiktok {
  /* width: 62%; Set the width to 60% */
  margin: 0 auto;
  display: flex;
  /* padding: 1%; */
  justify-content: justify;
  overflow-x: hidden;

  /* overflow:hidden */
  /* border:1px solid red Allow horizontal scrolling */
}

.links-grid-container-tiktok {
  display: flex; /* Arrange children in a row */
  flex-wrap: nowrap; /* Prevent wrapping to next line */

}

.creator-feed-image-wrapper-tiktok {
  /* border:1px solid red; */
  flex: 0 0 auto;
  width: 200px;
  height: 300px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 15px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.creator-feed-card-tiktok-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow:0 2px 0.75rem -0.25rem rgba(10,20,26,.08);
  border: 1px solid #e6e7eb;
  padding-bottom: .325rem;
  transition: all .15s ease-in-out;
  position: relative;
}

.template-shop-btn-tiktok{
  /* border:1px solid purple; */
  width:200px;
  background-color: black;
  color: white;
  border:none;
  margin-top: 1%;

}
.template-shop-btn-tiktok:hover{
  /* border:1px solid purple; */
  width:200px;
  background-color: grey;
  color: white;
  border:none;
  margin-top: 1%;

}



.horizontal-scroll-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  padding: 16px;
}

.horizontal-scroll-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}