.outer-container-reels{
  width: auto; /* Set the width to 60% */
  margin: 0 auto;
  display: flex;
  justify-content: justify;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth; /* Added for smooth scrolling */
  white-space: nowrap;
}
::-webkit-scrollbar {
  display: none;
}
.links-grid-container-reels {
  display: flex; /* Arrange children in a row */
  flex-wrap: nowrap; /* Prevent wrapping to next line */
  text-decoration: none;
  align-items: flex-start;
}

@media (max-width: 432px) {
  .links-grid-container-reels {
    width: 160px !important;
  }
}

@media (max-width: 344px) {
  .links-grid-container-reels {
    width: 140px !important;
  }
}

@media (max-width: 661px) {
  .links-grid-container-reels {
    width: 180px !important;
  }
}


.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.links-grid-container-reels a{
  text-decoration: none;
}
span .creator-feed-image-wrapper {
  flex: 0 0 auto;
  width: 200px;
  height: 300px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 15px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.creator-feed-card-reels-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow:0 2px 0.75rem -0.25rem rgba(10,20,26,.08);
  border: 1px solid #e6e7eb;
  padding-bottom: .325rem;
  transition: all .15s ease-in-out;
  /* position: relative; */
}

/* TiktokData.css */
.horizontal-scroll-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  padding: 16px;
}

.horizontal-scroll-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
