.yt-post-img-wrp {
	width: 100%;
	height: 169px;
	overflow: hidden;
	
	border-radius: 5px;
	position: relative;
}
.yt-post-img {
	object-fit: cover;
	width: 100%;
	/* object-position: 0% 5%; */
	height: 100%;
}
.yt-post-playbtn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	color: rgba(255, 255, 255, 0.8);
	font-size: 5rem;
	cursor: pointer;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}
.yt-post-playbtn:hover {
	color: rgba(255, 255, 255, 1);
}
.yt-post-timestamp {
	cursor: pointer;
	background-color: transparent;
}
.yt-post-timestamp-box {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-top: 7px;
	flex-wrap: wrap;
}
@media screen and (max-width: 600px){
	.yt-post-img-wrp {
	  height: 169px;
  }
  
  }