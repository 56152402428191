.comments-post-img-wrp {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 5px;
  
  position: relative;
}
.comments-post-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: #efeded;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comments-post-playbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: rgba(255, 255, 255, 0.8);
  font-size: 5rem;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comments-post-playbtn:hover {
  color: rgba(255, 255, 255, 1);
}
.comments-post-img-date {
  color: rgb(67, 66, 66);
  font-size: 1.5rem;
}
.comments-post-img-date p {
  text-align: center;
}
.comments-post-btn {
  border: none;
  /* background-color: #fff; */
  color: black;
  background-color: lightgrey;
  font-size: 14px;
  border-radius: 5px;
}
.comments-post-btn:hover {
  border: none;
  background-color: #414141;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.comments-post-img-date {
  text-align: center;
}

.date, .comments-label {
  display: block;
}

.display-flex{
  display: flex;
}

.bg-f1 {
  background-color: #f1f1f1;
}

.m-0 {
  margin: 0;
}

.bs-link{
  margin-left: 6px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: #000;
}

.social-img{
  height: 18px;
  margin-left: 6px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: #000;
  text-decoration: none;
}

.post-caption{
  color: black;
  margin: auto;
  text-align: center;
  margin-top: 35%;
}

.post-comment {
  display: grid;
  place-items: center;
}

.insta-height{
  height: 18px;
  margin-left: 6px;
}

.ml-6 {
  margin-left: 6px;
}

@media screen and (max-width: 600px){
  .comments-post-img-wrp {
    height: 300px;
}

}