.select-account-container {
	background-color: #282828;
	color: #ededed;
	min-height: 100vh;
	padding: 4%;
	width: 100%;
}
.select-account-content-box {
	max-width: 600px;
	margin: 0 auto;
	width: 100%;
}
.select-account-content-title {
	color: white;
	font-size: 1.5rem;
	margin-top: 20%;
}
.select-account-error-content-title {
	color: white;
	font-size: 1.3rem;
	opacity: 1;
	margin-top: 50px;
	text-align: center;
}
.select-account-username-box-flex {
	display: flex;
	gap: 20px;
	flex-direction: column;
	margin-top: 80px;
	justify-content: center;
	align-items: center;
}
.select-account-username-box {
	background-color: white;
	border-radius: 7px;
	display: flex;
	align-items: center;
	/* justify-content: start; */
	justify-content: flex-start;

	max-width: 400px;
	width: 400px;
	padding: 10px 25px;
	gap: 15px;
	cursor: pointer;
	color: #282828;
}
.select-account-username-box:hover {
	background-color: #ededed;
}
.select-account-username-box.active {
	background-color: #282828;
	color: white;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 1);
}
.select-account-username-box p {
	font-size: 1.2rem;
	margin: 0;
}

.select-account-username-box-icon {
	background-color: #282828;
	color: #ededed;
	border-radius: 99px;
	height: 40px;
	width: 40px;
	min-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.select-account-username-box-icon.active {
	color: #282828;
	background-color: #ededed;
}

.select-content {
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: auto 0;
}

.select-content p {
	margin-top: 30px;
	font-size: 1rem;
	text-align: center;
}

.font-1 {
	font-size: 1rem
}

.font-half {
	font-size: 1.5rem;
}

.m-0 {
	margin: 0;
}

.bubble-btn {
	margin-top: 80px;
	margin-bottom: 100px;
	display: flex;
	justify-content: start;
}

.visible-calender {
	text-align: center;
	margin-top: 40px;
}

.alert-msg{
	margin: 20% 0 0 0;
}

.bubble-white-btn {
	margin-top: 50px;
	margin-bottom: 100px;
	display: flex;
	justify-content: center;
}

@media (max-width: 800px) {
	.select-account-content-box {
		padding: 0 5%;
	}
	.select-account-content-title {
		margin-top: 20%;
		font-size: 1.2rem;
		text-align: center;
	}
}

@media screen and (max-width: 640px) {
	.select-account-username-box {
		max-width: 100%;
		width: 100%;
	}
}
