.tags-box-see-all {
	text-decoration: underline;
	cursor: pointer;
	font-size: 14px;
	/* color: #007bff; */
}

.tags-box-see-less {
	display: none;
}

.tags {
	margin-top: 3%;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.tags-container {
	background-color: black;
	color: white;
	display: flex;
	border-radius: 5px;
	padding: 2px 5px;
	align-items: center;
}

.tags-container p {
	margin: 0;
	font-size: 14px;
	color: white;
}

.closeIcon {
	margin-left: 10px;
	margin-right: 4px;
	cursor: pointer;
	color: white;
	font-size: 18px;
}

@media screen and (max-width: 540px) {
	.tags-box-see-less {
		text-decoration: underline;
		cursor: pointer;
		font-size: 14px;

		/* color: #007bff; */
		display: block;
	}
}