.pagination-container {
  display: flex;
  align-items: center;
}

.pagination-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.pagination-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.outer-container-reels-skeleton{
  width: auto; /* Set the width to 60% */
  margin: 0 auto;
  display: flex;
  justify-content: justify;
  overflow: auto;
  /* overflow-x: hidden; */
  /* overflow:hidden */
  /* border:1px solid red Allow horizontal scrolling */
}


.links-grid-container-reels-skeleton {
  display: flex; /* Arrange children in a row */
  flex-wrap: nowrap; /* Prevent wrapping to next line */
}

.total-pages {
  margin: auto;
  margin-right: 0px;
  white-space: nowrap;
}