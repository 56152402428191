.links-grid-container {
  display: flex;
  /* Arrange children in a row */
  flex-wrap: nowrap;
}

.outer-container {
  width: auto;
  /* Set the width to 60% */
  margin: 0 auto;
  display: flex;
  justify-content: justify;
  overflow-x: hidden;
}

span .creator-feed-image-wrapper {
  flex: 0 0 auto;
  width: 200px;
  height: 280px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 15px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.stay-up-to-date .creator-feed-card-video-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
  border: 1px solid #e6e7eb;
  padding-bottom: .325rem;
  transition: all .15s ease-in-out;
  position: relative;
}

.item-grid a {
  text-decoration: none;
}