.loginAuth {
	width: 65%;

	padding-left: 5%;
	padding-top: 3%;
	padding-right: 5%;
}
.providers {
	width: 80%;
	align-self: center;
	height: auto;

	text-align: center;
	margin: 8% auto;
}

.providers h2 {
	font-size: 2.5rem;
	color: #fff;
}
/* .input-form {
	display: block;
}
.input-div {
	margin: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.input-div input {
	width: 100%;
	text-align: center;
	border: 1px solid #404040;
	margin: 2% auto 2% auto;
	background-color: #404040;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px;
}
.submit-btn {
	width: auto;
	border: 1px solid #404040;
	margin: 4% auto 2% auto;
	background-color: #101010;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px;
}
.submit-btn:disabled {
	border: 1px solid #fe7171;
}
.auth-reset-password {
	margin-top: 4%;
	font-size: 1rem;
}

.auth-reset-password a {
	background-color: inherit;
	color: #ededed;
}
.login-animation {
    width: 50%;
    background-color: #171717;
    border-left: 1px solid #707070;;
  }
  .auth-right-panel { 
    margin-top: 45%;
    margin-left: 20%;
    margin-right: 10%;
  }
  .auth-creator-quote { 
    font-size: 1.75rem;
    font-weight: 400;
    color: #fff;
  }
  
  .auth-creator-image { 
    width: 75px;
    height: 75px;
    border-radius: 50px;
  }
  
  .auth-creator-name { 
    font-size: 1rem;
    display: table-cell;
    vertical-align: middle;
    padding-left: 5%;
    color: #707070;
  }
  
  .auth-creator-quote-image { 
    font-size: 160px;
  }
  
  .auth-creator-info { 
    display: table; 
    grid-template-columns: 20% 80%;
    margin-top: 15px;
  }
  @media screen and (max-width: 767px) {
	.auth-container {
	  flex-direction: column;
	}
	.loginAuth {
		width: 100%;
	
		padding-left: 5%;
		padding-top: 3%;
		padding-right: 5%;
	}
  
	.login-animation {
	  display: none;
	}
  
	.providers {
	  width: 100%;
	}
    } */

	
	.reset-btn{
		border:none;
		background: none;
		/* color: white; */
		text-decoration: underline;
	}
	.auth-container {
		display: flex;
		/* background-color: #282828; */
		/* color: #ededed; */
		min-height: 100vh;
	}
	.auth-options-container {
		width: 45%;
		padding-left: 5%;
		padding-top: 3%;
		padding-right: 5%;
	}

	/* ResetPassword.css */

/* ResetPassword.css */

.auth-options-container {
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
	/* border: 1px solid red; */
	width: 100%; /* Added width: 100% */
  }
  
  .providers {
	/* background-color: #fff; */
	/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
	padding: 20px;
	border-radius: 8px;
  }
  
  .input-form {
	display: flex;
	flex-direction: column;
	/* margin-top: 20px; */
  }
  
  .input-div {
	margin-bottom: 20px;
  }
  
  input {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	/* border: 1px solid #ccc; */
	border-radius: 4px;
  }
  
  .submit-btn {
	/* background-color: #007bff; */
	/* color: #fff; */
	padding: 10px;
	font-size: 16px;
	cursor: pointer;
	border: none;
	border-radius: 4px;
  }
  
  .submit-btn:disabled {
	/* background-color: #d3d3d3; */
	cursor: not-allowed;
  }
  
  .success-msg,
  .error-msg {
	margin-top: 20px;
  }
  
  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
	.auth-options-container {
	  padding: 10px;
	  max-width: 100%;
	  max-height:100vh;
	}
  
	.input-form {
	  margin-top: 10px;
	}
  
	.success-msg,
	.error-msg {
	  margin-top: 10px;
	}
  }
  