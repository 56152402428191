.pinterest-post-img-wrp {
	width: 100%;
	height: 500px;
	overflow: hidden;
	border-radius: 5px;
	position: relative;
}
.pinterest-post-img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	background-color:#efeded;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pinterest-post-playbtn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	color: rgba(255, 255, 255, 0.8);
	font-size: 5rem;
	cursor: pointer;
	padding: 0;
	text-decoration: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}
.pinterest-post-playbtn:hover {
	color: rgba(255, 255, 255, 1);
}
.pinterest-post-img-date{
	color:rgb(67, 66, 66);
	 font-size: 1.5rem;
}
.pinterest-post-img-date h4{

	text-align: center;
}
.pinterest-post-btn{
	border:none;
	background-color: #fff;
	color: black;
}
.pinterest-post-btn:hover{
	border:none;
	background-color: #fff;
	color: blue;
}
@media screen and (max-width: 600px){
	.pinterest-post-img-wrp {
	  height: 305px;
  }
  
  }
  