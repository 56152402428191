.skeleton-content {
	margin-top: -0.3%;
	height: 800px;
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.dash-row {
	margin-top: -1;

	display: grid;
	grid-template-columns: 90% 10%;
	padding-top: 0.67rem;
	padding-bottom: 2rem;
}

.skeleton-img-div {
	margin-left: 70%;
	margin-top: -7.5%;

}

.skeleton-creator {
	text-align: center;
	margin-top: 1.5%;
	position: sticky;
	/* margin: auto; */
}

.skeleton-creator-page {
	text-align: center;
	margin-top: 1.5%;
	position: sticky;
	/* margin: auto; */
}

.creator-img-skeleton {
	margin-top: -6%;

}

.search-container-skeleton {
	width: 100%;
	box-sizing: border-box;
	border: none;
	outline: none;
	background-color: #f1f1f1;
	/* margin: auto; */
	height: 75px;
	margin-top: 6.3%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 15px;
}

.skeleton-hashtagsFilter {
	display: flex;

	width: 100%;
	height: 40%;
	margin-top: 1.2%;
	margin-right: 2%;
}

.skeleton-contentFilter {
	display: flex;
	margin-top: 10%;
	width: 100%;
	height: 30%;
}

.skeleton-hashtagFilter {
	width: 10%;
	height: 100%;
	margin-right: 1%;
}

.skeleton-hashtag {
	margin-top: 1%;
}

.skeleton-hashtag-container {
	width: 15%;
	margin-right: 1%;
	height: max-content;
	margin-top: 0%;
}

.skeleton-content-container {
	width: 9%;
	margin-right: 1%;
	height: max-content;
	margin-top: 0%;
}

.skeleton-account-grid-item {
	border-radius: 10px;
	background: #f1f1f1;
	padding: 40px;
}

.skeleton-analytics-grid-item {
	border-radius: 10px;
	background: #f1f1f1;
	padding: 29px;
	font-size: 1.25rem;
}

.skeleton-dashboard-grid-item {
	border-radius: 10px;
	background: #f1f1f1;
	padding: 40px;
	margin-top: 5%;
}

.skeleton-controls-grid-item {
	border-radius: 10px;
	background: #f1f1f1;
	padding: 33px;
	margin-bottom: 2.5%;
	font-size: 1.25rem;
}

.horizontal {
	margin-top: 0.85%;
}

.skeleton-analytics-titles {
	padding-top: 1.25rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, 350px);
	grid-column-gap: 25px;
	grid-row-gap: 25px;

	padding-bottom: 1rem;
}

.skeleton-push-btn-div {
	display: flex;
	flex-direction: row;
}

.skeleton-index-column {
	margin-top: 14%;
	border-radius: 4px;
	width: 32%;
	/* height: 100px; */
	padding: 0;
	margin-left: 32%;
}

.skeleton-index-btn {
	height: 1.5rem;
}

.skeleton-video {
	height: 14rem;
	width: 8.5rem;
	margin-left: 14%;
}

.skeleton-globalcontentFilter {
	display: flex;
	margin-top: 3.6%;
	width: 100%;
	height: 40%;
}

.skeleton-globalhashtag {
	margin-top: 4.3%;
}

.skeleton-globalcontent {
	margin-top: -0.3%;
}

.skeleton-thumbnail {
	width: 100%;
	height: 100%;
}

.skeleton-val {
	display: grid;
	grid-template-columns: repeat(auto-fill, 350px);
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	padding-top: 1.25rem;
	padding-bottom: 4rem;
}

.skeleton-drawer {
	height: 20rem;
}

.skeleton-dashboard {
	display: grid;
	grid-template-columns: repeat(auto-fill, 350px);
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	padding-top: 1.25rem;
	padding-bottom: 4rem;
	margin-top: 4%;
}

.thumbnail-div {
	width: 100%;
	text-align: center;
	margin-left: -20%;
}

.skeleton-hashtags,
.skeleton-hashtags-scroll {
	height: fit-content;
	justify-content: flex-start;
}

.skeleton-hashtags {
	vertical-align: middle;
	margin-top: 0.5rem;
}

.skeleton-hashtags-section-title {
	font-size: 1.1em;
	margin: 0;
	color: #000;
	display: block;

	margin-block-start: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}

.skeleton-hashtags-scroll {
	display: flex;
	overflow: scroll;
	overflow-y: scroll;
	padding-top: 1rem;
	gap: 10px;
}

.skeleton-feed-wrapper {
	display: flex;
	flex-direction: column;
	z-index: 1;
	color: #f1f1f1;
	justify-content: center;
	width: 100%;
}

.skeleton-feed {
	display: grid;
	grid-template-columns: repeat(auto-fill, 300px);
	justify-content: center;
	grid-gap: 25px;
	margin-bottom: 2rem;
	margin-top: 2rem;
	overflow: hidden;
	/* height: 100%; */
}

.skeleton-hashtags-scroll::-webkit-scrollbar {
	display: none;
}

.empty-div {
	margin-top: 4.5%;
}

@media only screen and (max-width:640px) {
	.empty-div {
		top: 4rem
	}

	.skeleton-creator {
		margin-top: -11%;
	}

	.skeleton-creator-page {
		margin-top: 2.4%;
	}

	.search-container-skeleton {
		margin-top: 11%;
	}

	.skeleton-hashtagsFilter {
		margin-top: 3%;
	}

	.skeleton-hashtag {
		margin-top: 1%;
	}

	.header-skeleton-wrapper {
		margin-top: -16%
	}

	.header-skeleton-logo-tittle {
		color: #000;
		font-size: 1.8rem;
		font-weight: bold;
		/* margin: 0; */
	}

	.skeleton-img-div {
		margin-left: 93%;
		margin-top: -17%;
	}
}

@media only screen and (max-width: 850px) {
	.skeleton-content-container {
		width: 18%;
		margin-right: 3%;
		height: max-content;
		margin-top: 0%;
	}

	.skeleton-hashtag-container {
		width: 30%;
		margin-right: 1%;
		height: max-content;
		margin-top: 0%;
	}

	.skeleton-hashtagFilter {
		width: 30%;
		height: 100%;
		margin-right: 1%;
	}

	.skeleton-img-div {
		/* float: right; */

		position: relative;
		right: 80%;
	}

	.skeleton-index-column {
		/* margin-top: 14%; */

		border-radius: 4px;
		width: 5rem;
		/* height: 100px; */
		padding: 0;
		/* margin-left: 80%; */
	}

	.skeleton-index-btn {
		height: 1.5rem;
	}

	.skeleton-video {
		height: 12rem;
		width: 6.5rem;
		margin-left: 2%;
	}

	.skeleton-column {
		margin-left: 70%;
	}
}

@media (max-width: 767.98px) {
	.skeleton-val .skeleton-dashboard {
		padding-top: 1.25rem;
		justify-content: center;
	}

	.skeleton-dashboard {
		margin-top: 0%;
	}
}

.table-responsive {
	min-height: 0.01%;
	overflow-x: auto;
	margin-top: -2%;
}

@media screen and (max-width: 767px) {
	.table-responsive {
		margin-bottom: 15px;

		border: 1px solid #ddd;
	}

	.table-responsive>.table {
		margin-bottom: 0;
	}

	.table-responsive>.table input {
		max-width: 60%;
		margin-left: -10%;
	}

	.table-responsive>.table>thead>tr>th,
	.table-responsive>.table>tbody>tr>th,
	.table-responsive>.table>tfoot>tr>th,
	.table-responsive>.table>thead>tr>td,
	.table-responsive>.table>tbody>tr>td,
	.table-responsive>.table>tfoot>tr>td {
		white-space: nowrap;
	}
}

@media screen and (max-width: 535px) {
	.thumbnail-div {
		width: 150px;
	}

	.index-btn {
		margin-top: 4%;
		width: fit-content;
	}

	.table-responsive {
		margin-bottom: 15px;

		border: 1px solid #ddd;
	}

	.table-responsive>.table {
		margin-bottom: 0;
	}

	.table-responsive>.table input {
		max-width: 60%;
		margin-left: -14%;
	}

	.table-responsive>.table>thead>tr>th,
	.table-responsive>.table>tbody>tr>th,
	.table-responsive>.table>tfoot>tr>th,
	.table-responsive>.table>thead>tr>td,
	.table-responsive>.table>tbody>tr>td,
	.table-responsive>.table>tfoot>tr>td {
		white-space: nowrap;
	}
}

.skeleton-tab-posts-table {
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: hidden;
}

.skeleton-tab-post-row {
	display: flex;
	gap: 30px;
}

.skeleton-tab-post-row-grab {
	cursor: grab;
}

.skeleton-tab-row-media {
	width: 35%;
	height: 300px;
	text-align: center;
	position: relative;
	/* max-height: 300px; */
}

.skeleton-tab-row-media-wrapper {
	width: 100%;
	height: 100%;
	/* height: 100%; */
}

.skeleton-tab-row-actions-mobile {
	display: none;
}

.skeleton-tab-row-tags {
	width: 40%;
}

.skeleton-tab-media {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

.skeleton-tab-actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	width: 25%;
	gap: 15px;
	align-items: stretch;
	padding: 0 10px;
}

.skeleton-tab-buttons {
	width: 80%;
	color: black;
	border: solid black 1px;
	border-radius: 5px;
}

.skeleton-search-container-tagInput {
	width: 100%;
	box-sizing: border-box;
	border: none;
	outline: none;
	background-color: #f1f1f1;
	margin: auto;
	height: 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 15px;
	overflow: hidden;
	margin-bottom: 20px;
}

.skeleton-tab-index-btn {
	height: 32px;
}

@media screen and (max-width: 540px) {
	.skeleton-tab-post-row {
		gap: 30px;
		flex-direction: column;
		border-bottom: gainsboro 1px solid;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.skeleton-tab-row-media {
		width: 100%;
		max-height: 300px;
		display: flex;
		align-items: stretch;
		overflow: hidden;
	}

	.skeleton-tab-media {
		border-radius: 0px;
	}

	.skeleton-tab-row-media-wrapper {
		width: 50%;
		height: 100%;
	}

	.skeleton-tab-row-actions-mobile {
		display: block;
		width: 50%;
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-direction: column;
		gap: 15px;
		padding: 0 10px;
	}

	.skeleton-tab-row-tags {
		width: 100%;
	}

	.skeleton-tab-actions {
		display: none;
	}

	.skeleton-creator {
		margin-top: -16%;
	}

	.search-container-skeleton {
		margin-top: 15%;
	}
}

.page-skeleton {
	margin-top: -2%
}

.header-skeleton-wrapper {
	margin-top: -0.8%
}

.header-skeleton-logo-tittle {
	color: #000;
	font-size: 2rem;
	font-weight: bold;
	margin: 0;
}

.feed-search-skeleton {
	width: 90%;
	box-sizing: border-box;
	border: none;
	outline: none;
	background-color: #f1f1f1;
	margin: auto;
	height: 75px;
	margin-top: 3.3%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 15px;
}

.feed-search-wrapper {
	width: 90%;
	height: 100%;
	border: none;
	outline: none;
	color: #000;
	font-size: 18px;
	border-radius: 50px;
	padding-left: 25px;
	float: left;
	background: #f1f1f1;
}

.search-input {
	width: 95%;
	height: 100%;
	border: none;
	outline: none;
	color: #000;
	font-size: 18px;
	border-radius: 50px;
	padding-left: 25px;
	float: left;
	background: #f1f1f1;
	margin-top: 6%;
}

.push-btn {
	border-radius: 10px;
	width: 200px;
	padding: 20px;
}

.mr-3 {
	margin-right: 3%;
}

.ml-15 {
	margin-left: 15%;
}

.skeleton-tag {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.hw-40 {
	height: 40px;
	width: 40px;
}

.hw-100 {
	height: 100px;
	width: 100px;
}

.card-skeleton {
	width: 100%;
	height: 300px;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 0px;
}

.skeleton-hashtag {
	padding: 16px;
	margin-top: -33%;
	border-radius: 10px;
}

.hashtag-filter {
	height: 8%;
	border: none;
	background-color: hwb(0 89% 9%);
	padding: 16px;
	border-radius: 10px;
	margin-right: 1%;
	color: #000;
}

.skeleton-feed,
.feed-search-skeleton {
	max-width: 80rem;
}

@media (min-width:1056px) and (max-width:1416px) {

	.skeleton-feed,
	.feed-search-skeleton {
		max-width: 60rem;
	}
}

@media (min-width:700px) and (max-width:1055px) {

	.skeleton-feed,
	.feed-search-skeleton {
		max-width: 39.5rem;
	}
}