.yt-channel-account-box {
	background-color: white;
	border-radius: 7px;
	display: flex;
	align-items: center;
	/* justify-content: start; */
	justify-content: flex-start;

	max-width: 400px;
	width: 400px;
	padding: 10px 25px;
	gap: 15px;
	color: #282828;
}
.yt-channel-account-box-img-wrp {
	border-radius: 99px;
	height: 60px;
	width: 60px;
	overflow: hidden;
	position: relative;
}
.yt-channel-account-box-img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.yt-channel-account-box-name {
	font-size: 1.2rem;
	margin: 0;
}
.yt-channel-account-box-details {
	font-size: 0.9rem !important;
	margin: 0;
}
@media screen and (max-width: 640px) {
	.yt-channel-account-box {
		max-width: 100%;
		width: 100%;
	}
}
.select-account-progress {
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: auto 0;
}

.select-account p {
	margin-top: 30px;
	font-size: 1rem;
	text-align: center;
}

.yt-channel {
	display: flex;
	gap: 10px;
}

.m-0 {
	margin: 0;
}

.bubble-back-btn {
	margin-top: 80px;
	margin-bottom: 100px;
	display: flex;
	justify-content: start;
	gap: 20px;
}

.font-half {
font-size: 1.5rem;
}