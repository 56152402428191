/* .subscribe-footer { 
    background-color: #171717;
    color: #fff;
    height: 250px;
    display: flex;
    padding-top: 6%;
    padding-left: 4%;
    width: 100%;
} */

.stripe-table {
   width: 90%;
   margin: auto;
   margin-top: 3%;
   margin-bottom: 3%;
}

.bg-dark{background-color:#343a40!important}
.py-4{padding-bottom:1.5rem!important}

.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}@media (min-width:576px){.container{max-width:540px}}@media (min-width:768px){.container{max-width:720px}}@media (min-width:992px){.container{max-width:960px}}@media (min-width:1200px){.container{max-width:1140px}}
.text-center{text-align:center!important}
.text-muted{color:#6c757d!important}
.mb-0,.my-0{margin-bottom:0!important}
.py-2{padding-top:.5rem!important}



.howItWorksGrid { 
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center; 
    align-items: center;
    width: 60%;
}

.howItWorksGridMobile { 
    display: none;;
}

.featureVideo { 
    width: 100%;
}

.featureTitle { 
    color: #2449fc;
    font-family: "Link Sans", "Arial Black", Arial, sans-serif;
    font-size: clamp(28px, 8.5vmin, 32px);
    font-weight: 820;
    letter-spacing: -0.02em;
    line-height: 1.05;
    text-align: left;
    margin-bottom: 16px;
}

.featureDescription { 
    font-family: "Link Sans", "Arial Black", Arial, sans-serif;
    font-size: clamp(16px, 2vmin, 20px);
    font-weight: 418;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-bottom: 16px;
}

.featureVideo { 
    display: block;
    margin: 0 auto;
}

/* .auth-container.how-it-works { 
    align-content: space-between;
} */

.contact-form{
    max-width: 600;
    margin: "0 auto";
    padding: 0px;
}

@media screen and (max-width: 767px) {

    .stripe-table {
        width: 80%;
     }

     .howItWorksGridMobile { 
        margin: auto;
        display: grid;
        grid-template-columns: 100%;
        text-align: center; 
        align-items: center;
        width: 70%;
        grid-row-gap: 25px;
        padding-top: 60px;
        padding-bottom: 25px;
    }

    .howItWorksGrid { 
        display: none;;
    }

    .featureTitle { 
        font-size: clamp(24px, 8.5vmin, 24px);
    }
    
    .featureDescription { 
        font-family: "Link Sans", "Arial Black", Arial, sans-serif;
        font-size: clamp(12px, 2vmin, 12px);
        font-weight: 418;
        letter-spacing: -0.02em;
        line-height: 1.5;
        margin-bottom: 16px;
    }
}

@media (max-width: 889px) {
.contact-form{
    max-width: 600;
    margin: "0 auto";
    padding: 10px 30px !important;;
}
}


