.option-select{
	-webkit-appearance: none;
	width: 26px;
}

.auth-container {
	/* display: flex; */
	display: grid;
	/* background-color: #282828; */
	background-color: #0F1013;
	color: #ededed;
	/* max-height: 100vh; */
}

.auth-options-container {
	width: 45%;
	padding-left: 5%;
	padding-top: 3%;
	padding-right: 5%;
}

.auth-phone-signup-container {
	width: 100%;
}
.auth-signup-details {
	max-width: 600px;
	margin: 20px auto 0 auto;
}
.auth-signup-details-title {
	font-size: 2rem;
	color: #fff;
	text-align: center;
}
.auth-signup-details-subtitle {
	font-size: 1rem;
	color: #fff;
	margin: 0;
}
.auth-signup-details-subtitle.active {
	font-size: 1.7rem;
	color: #fff;
}
.auth-signup-tabs-platforms {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: solid rgba(255, 255, 255, 0.1) 1px;
	padding: 8px 15px;
	border-radius: 9px;
	cursor: pointer;
	margin-top: 1rem;
}
.auth-signup-tabs-platforms.active {
	border-radius: 9px 9px 0 0;
	border: solid rgba(255, 255, 255, 0.1) 1px;
	border-bottom: none;
}
.auth-signup-tab-title {
	font-size: 1.5rem;
	color: #fff;
	margin: 0;
	font-weight: 500;
}
.auth-signup-tab-icon {
	font-size: 1.1rem;
	margin: 0 8px 3px 0;
}
.auth-signup-tab-icon.active {
	font-size: 16px;
}
.auth-signup-tab-wrp {
	padding: 25px;
	margin-bottom: 1rem !important;
	background-color: #2f2d2d;
	border: solid rgba(255, 255, 255, 0.1) 1px;
}
.auth-signup-tab-channelid-text {
	color: white;
	text-decoration: underline;
	margin: 20px 0 0 0;
	font-size: 0.9rem;
	cursor: pointer;
}
.auth-signup-tab-channelid-text:hover {
	color: white;
}
.ant-input-affix-wrapper-focused.auth-signup-channelId-btn {
	box-shadow: none !important;
	border: none !important;
}

.auth-facebook-btn {
	width: 100%;
	border: 1px solid #404040;
	background-color: #404040;
	border-radius: 5px;
	color: #ededed;
	font-size: 1.2rem;
	padding: 5px;
	text-align: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.auth-facebook-btn:hover {
	border: 1px solid #fff;
}

.auth-phone-signup-container-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* width: 45%;
	padding: 4%; */
	width: 100%;
	padding-bottom: 75px;
}
.auth-form-wrapper {
	text-align: center;
	/* max-width: 600px;
	margin: 0 auto;
	width: 100%;
	flex: 1;
	padding-top: 20%; */

	width: 60%;
	height: auto;
	/* max-width: 80rem;
	min-height: 4.5rem; */
	justify-content: center;
	align-items: left;
	margin-left: auto;
	margin-right: auto;

}

.auth-creator-quote-container {
	width: 55%;
	background-color: #171717;
	border-left: 1px solid #707070;
	height: 100%;
	/* display: flex; */
	/* justify-content: center;
	align-items: center; */
	display: none;
}
.auth-right-panel {
	margin: 0 10% 0 20%;
}

.modal {
	position: relative;
	align-content: center;
	justify-content: center;
}

.parentDisable {
	position: fixed;
	top: 0;
	left: 0;
	background: #fefefe;
	opacity: 1;
	z-index: 998;
	height: 100%;
	width: 100%;
	display: flex;
	color: #000;
	flex-direction: column;
	justify-content: center;
}
.timer-page-title {
	margin: 4% 5%;
}
.row {
	display: flex;
}

.provider-btn {
	border: 1px solid #404040;
	margin: 4% auto 2% auto;
	background-color: #404040;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px;
	width: 100%;
	text-align: center;
}
.provider-btn:hover {
	background-color: #404040;
	cursor: pointer;
	border: 1px solid #fff;
}
.provider-btn-facebook {
	width: 70%;
	border: 1px solid #404040;
	margin: 4% auto 2% auto;
	font-size: 1rem;
	padding: 5px;
	background-color: #fff;
	border-radius: 16px;
}

.provider-btn-facebook:hover {
	color: #fff;
	background-color: #404040;
}

select#socialPlatforms { 
	background-color: black;
	color: white; 
	padding: 5px;
	margin-top: 10px;
	margin-bottom: 0px;
	border-radius: 5px;
}

.input-div.signup {
	margin: auto;
	display: flex;
	align-items: center;
	width: 100%;
}

.input-form {
	display: block;
}
.input-div {
	margin: auto;
	display: flex;
	align-items: center;
	width: 60%;
}
.input-div {
	margin: auto;
	display: flex;
	align-items: center;
}
.input-div input {
	width: 100%;
	text-align: center;
	border: 1px solid #404040;
	/* margin: 2% auto 2% auto; */
	background-color: #404040;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px;
}
.auth-input-label {
	width: 100%;
	margin: 10px 0 -10px 0;
	text-align: left;
	font-size: 0.9rem;
}
.auth-signup-url-desc {
	color: #c6c6c6;
	margin-top: 8px;
	font-size: 0.8rem;
}
.auth-signup-url-link {
	color: #c6c6c6;
	/* text-align: center; */
	font-size: 1.2rem;
	font-family: sans-serif;
	font-weight: 100;
}
.submit-btn {
	width: auto;
	border: 1px solid #404040;
	/* margin: 4% auto 2% auto; */
	background-color: #101010;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px;
}
.submit-btn:disabled {
	border: 1px solid #fe7171;
}
.continue-btn {
	width: 5em;
	border-radius: 16px;
	background-color: #404040;
	color: #fff;
	border-width: 1px;
}
.continue-btn:disabled {
	width: 5em;
	border-radius: 16px;
	background-color: #fff;
	color: #404040;
	border-color: #404040;
	border-width: 1px;
}
.continue-btn a {
	text-decoration: none;
	color: #fff;
}

.back-btn {
	margin: auto;
	color: #fff;
	font-size: 1rem;

	background-color: #282828;

	text-align: right;
	transform: rotate(180deg);
}

.back-btn a {
	color: #fff;
	text-decoration: none;
	background-color: #282828;
}

.back-btn a:hover {
	color: inherit;
}

.error-msg {
	margin-top: 4%;
	margin-bottom: 3%;
	color: red;
}
.success-msg {
	margin-top: 4%;
	margin-bottom: 3%;
	color: green;
}
.timer {
	margin-top: -20%;
	text-align: center;
	margin: auto;
}
.timer-msg {
	margin-top: 8%;
	font-size: 1.5rem;
}
.time-circle {
	width: fit-content;
	margin: auto;
}
.otp-text {
	font-size: x-small;
	color: #707070;
}
.auth-terms {
	color: #707070;
	font-size: 12px;
	text-align: center;
	width: 100%;
	/* margin-bottom: 10%; */
	/* margin-bottom: 5%; */
	padding-bottom: 5%;
}
.auth-terms a{
	color: #707070;
	font-size: 12px;
	text-align: center;
	width: 100%;
	/* padding-bottom: 10%; */
}
.auth-terms a:hover{
	color: #fff;
	/* font-size: 12px;
	text-align: center;
	width: 100%;
	padding-bottom: 10%; */
}

@media only screen and (max-width: 1000px) {
	.auth-creator-quote-container {
		display: none;
	}
	.auth-phone-signup-container-flex {
		width: 100%;
	}
	.auth-form-wrapper {
		margin: 0 auto;
		width: 80%;
	}
}

#dropdown {
	align-content: center;
	justify-content: center;
	margin-right: 1%;
	margin-left: 0%;
}

.option-select {
	height: 100%;
	color: #fff;
	text-align: center;
	justify-content: center;
	border-radius: 5px;
	background-color: #404040;
	border-color: #404040;

	/* width: 50px; */
	/* width: 26px; */
	width: 27px;

	padding: 5px;
}

/* .auth-title-div {
	text-align: left;
} */

.auth-title-div-p {
	font-size: 1.8rem;
	/* margin-bottom: 1%; */
	color: #ededed;
	font-weight: 800;
}

.auth-title-div-p-2 {
	font-size: 1rem;
	color: #ededed;
	display: none;
}

.auth-small-text {
	margin-top: 4%;
	font-size: 1rem;
	display: none;
}

.auth-small-text a {
	background-color: inherit;
	color: #ededed;
}
.auth-reset-password {
	margin-top: 4%;
	font-size: 1rem;
}

.auth-reset-password a {
	background-color: inherit;
	color: #ededed;
}
.auth-creator-quote {
	font-size: 1.75rem;
	font-weight: 400;
	color: #fff;
	font-style: italic;
	position: relative;
}
.auth-creator-quote-quotes {
	font-size: 8rem;
	position: absolute;
	opacity: 0.4;
	left: -4rem;
	top: -3rem;
}

.auth-creator-image {
	width: 75px;
	height: 75px;
	border-radius: 50px;
}

.auth-creator-name {
	font-size: 1rem;
	display: table-cell;
	vertical-align: middle;
	padding-left: 5%;
	color: #707070;
}

.auth-creator-info {
	display: table;
	grid-template-columns: 20% 80%;
	margin-top: 25px;
}

.facebook-div {
	margin-top: 10%;
}
.scheduler-wrapper {
	margin-top: 3%;
	margin-bottom: -3%;
}
.scheduler-wrapper .back {
	margin-top: 2%;
	margin: auto;
	color: #000;
	font-size: 1rem;

	width: auto;
	text-align: center;
}
.back a {
	text-decoration: none;
}
.back :hover {
	color: #fff;
}
.resend-btn {
	border: none;
	background-color: inherit;
	color: #ededed;
	text-decoration: underline;
}
.resend-msg {
	margin-top: 6px;
	margin-bottom: -15px;
	font-size: 0.8rem;
}
.setup-link {
	color: blue;
	background-color: #fff;
}

.p-4 {
	padding: 4%;
}

.auth-pages {
	width: 100%;
	height: 80vh;
    display: flex;
    align-Items: center;
    justify-content: center;
    flex-Direction: column;
    margin: auto 0;
}

.progress {
	margin-top: 30px;
	font-size: 1rem;
	text-align: center;
}

.mt-2rem {
	margin-top: 2rem;
}

.subtile-auth {
	font-size: 1.2rem;
	text-align: center;
	margin-bottom: 3rem;
}

.font-1-2rem {
	font-size: 1.2rem;
}

.mb-1rem {
	margin-bottom: 1rem;
}

.m-0 {
	margin: 0;
}

.bs-facebook {
	font-size: 20px;
	margin-right: 20px;
}

.google-auth-hr {
	width: 100%;
	margin: 40px 0 0 0;
	z-index: 5;
}

.bs-chevron {
	margin: 0 6px 0 0;
}

.alert-margin {
	margin: 10px 0;
}

.error-channel {
	width: 40px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}
.login-auth {
	width: 100%;
	display: flex;
	min-height: 100%;
}
.loading {
	margin-top: 30px;
	font-size: 1.2rem;
}
.spinStyle-auth {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.p-4 {
	padding: 4%;
}
.alert-margin-30 {
	margin: 30px 0;
}
.auth-input {
	font-size: 10px;
	padding-top: 7px;
	margin-bottom: 0px;
}
.white-btn-bubble {
	text-align: right;
	margin-top: 40px;
	margin-bottom: 100px;
}
.grey-font {
	font-weight: 500;
	color: grey;
}
.mb-4 {
	margin-bottom: 4%;
}
.mt-0 {
	margin-top: 0;
}
.mb-6 {
	margin-bottom: 6%;
}
.mb-3 {
	margin-bottom: 3%;
}
.mr-20px{
	margin-right: 20px;
}
.m-0{
	margin: 0;
}

@media screen and (max-width: 1280px) {
	#dropdown {
		height: 36px;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.auth-small-text {
		margin-top: 5%;
		font-size: 0.75rem;
	}
	.auth-reset-password {
		margin-top: 5%;
		font-size: 0.75rem;
	}
	.auth-signup-details-title {
		font-size: 1.5rem;
	}
	.auth-signup-details-subtitle {
		font-size: 0.9rem;
	}
	.auth-input-label {
		margin: 10px 0 -5px 0;
	}
	.auth-signup-url-link {
		font-size: 0.9rem;
	}
}
