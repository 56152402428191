.skeleton-stat {
    margin: 0 auto;
    margin-bottom: 2%;
    width: 100%;
    height: 160px;
    margin-right: 4%;
}

.skeleton-style {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 15px;
    padding: 2%;
}

.removed-content {
    margin-bottom: 5%;
    padding: 2%;
    border-radius: 5px;
}

.removed-content h5 {
    font-weight: 500;
    color: grey;
}

.post-container {
    display: inline-block;
    margin-right: 10px;
    width: 140px;
    vertical-align: top;
}

.h-255 {
    height: 255px;
}

.mh-300 {
    max-height: 300px;
}

.mt-7 {
    margin-top: 7.5%;
}

.skeleton-stat-box {
    height: auto;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    width: 100%;
    vertical-align: top;
}