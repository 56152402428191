.links-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 25px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
  height: auto;
}
