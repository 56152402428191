.logo {
	text-align: center;
	width: 65%;
	height: auto;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	 user-select: none;
  }

  .logo.mission_container { 
    /* -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px); */
    height: 150px;
    border-radius: 20px;
  }
  
  .logo b{
	font: 1000 2rem "Vilbur";
	color: #fee;
	/* text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #fee, 0 0 0.5em #fee, 0 0 0.1em #fee, 0 10px 3px #000; */
  }
  .logo b span{
	animation: blink linear 2s 3;
  }
  .logo b span:nth-of-type(2){
	animation: blink linear 3s 2;
  }
  .logo b span:nth-of-type(3){
    animation: blink linear 4s 4;
  }
  .logo b span:nth-of-type(4){
    animation: blink linear 5s 2;
  }
  .logo b span:nth-of-type(5){
    animation: blink linear 6s 2;
  }
  @keyframes blink {
	78% {
	  color: inherit;
	  text-shadow: inherit;
	}
	79%{
	   color: #333;
	}
	80% {
	  
	  text-shadow: none;
	}
	81% {
	  color: inherit;
	  text-shadow: inherit;
	}
	82% {
	  color: #333;
	  text-shadow: none;
	}
	83% {
	  color: inherit;
	  text-shadow: inherit;
	}
	92% {
	  color: #333;
	  text-shadow: none;
	}
	92.5% {
	  color: inherit;
	  text-shadow: inherit;
	}
  }
  

.navbar_component {
  background-color: transparent;
  align-items: center;
  margin-top: 1.5rem;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

nav {
  z-index: 1000;
  background: #ddd;
  position: relative;
}

.navbar_container {
  width: 70%;
  height: min-content;
  max-width: 80rem;
  min-height: 4.5rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border: 1px solid var(--light-grey);
  background-color: var(--light-grey);
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  border-radius: 24rem;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 1rem;
  display: flex;
  margin-top: 5%;
  background-color: #343434;
  opacity: .95;
}

.mission_container {
	/* width: 60%; */
  width: 90%;
	height: auto;
	max-width: 80rem;
	min-height: 4.5rem;
	/* grid-column-gap: 1rem;
	grid-row-gap: 1rem; */
	border: 1px solid var(--light-grey);
	background-color: var(--light-grey);
	/* -webkit-backdrop-filter: blur(9px);
	backdrop-filter: blur(9px); */
	/* border-radius: 24rem; */
	justify-content: center;
	align-items: left;
	margin-left: auto;
	margin-right: auto;
	/* padding-left: 2rem; */
	/* padding-right: 1rem; */
	/* display: flex; */
	margin-top: 25%;
	/* background-color: #343434; */
	/* color: rgb(210, 232, 35); */
	/* background-color: #343434;
	padding: 50px;
	border-radius: 5px; */
  }

  .mission {
	color: rgb(239, 240, 236);
	font-family: "Link Sans", "Arial Black", Arial, sans-serif;
	text-shadow:1px 1px 1px #fff, 1px 1px 1px #ccc;
	font-size: clamp(20px, 8.5vmin, 30px);
	/* font-weight: 520; */
	letter-spacing: -0.04em;
	line-height: 1.5;
	text-align: center;
	position: absolute;
	/* width: 50%; */
	height: auto;
	max-width: 85rem;
	/* -webkit-backdrop-filter: blur(9px);
	backdrop-filter: blur(9px); */
  }

  .mission-description { 
	color: #919191;
	flex: 1;
	/* font-family: "Link Sans", "Arial Black", Arial, sans-serif; */
	font-weight: 520;
	font-size: 1.125rem;
	position: relative;
	text-align: center;
	top: 75px;
	/* width: 50%; */
	height: auto;
	max-width: 80rem;
	-webkit-backdrop-filter: blur(9px);
	backdrop-filter: blur(9px);
  }

.navbar_menu {
  align-items: center;
  display: flex;
  position: static;
}

.w-nav-menu {
  float: right;
  position: relative;
}

.navbar_menu-link-wrapper {
  align-self: center;
  align-items: center;
  display: flex;
  background-color: #343434;
}

.navbar_link.w--current {
  color: var(--white);
}
.navbar_link {
  color: var(--white);
  /* padding: 0.5rem 1rem; */
  text-decoration: none;
  font-weight: 600;
  margin-left: 15px;
}

.navbar_link.login {
  color: black;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: rgb(239, 240, 236);
  border-radius: 5px;
}

.navbar_link.sign-up {
  color: white !important;
  padding: 0.5rem 1rem;
  text-decoration: none;
  /* background-color:#4285f4; */
  background-color: #2449fc;
  /* background-color: rgb(30, 35, 48); */
  border-radius: 15px;
  margin-left: 10px;
}

.navbar_link.try {
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  /* background-color:#4285f4; */
  background-color: #2449fc;
  /* background-color: rgb(30, 35, 48); */
  border-radius: 15px;
  margin-left: 10px;
}

.navbar_link.demo {
  color: black;
  padding: 0.5rem 1rem;
  text-decoration: none;
  /* background-color:#4285f4; */
  background-color:rgb(239, 240, 236);
  /* background-color: rgb(30, 35, 48); */
  border-radius: 15px;
  margin-left: 10px;
}

nav-link {
  vertical-align: top;
  color: #222;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.logo-text {
  color: var(--white);
  font-family: Super Bubble, sans-serif;
  font-size: 1.75rem;
}

a:active,
a:hover {
  outline: 0;
  color: inherit;
}

.button-label {
  transform: translate3d(0px, -100%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  color: rgb(0, 0, 0);
}

.button-label_container {
  z-index: 5;
  height: 1.5rem;
  position: relative;
  overflow: hidden;
}

.homepage-wrapper {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: black;
}

.homepage-btns {
  z-index: 2000;
  width: 30%;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 5%;
  bottom: 5%;
  position: fixed;
  text-align: center;
}
.homepage-login-btn {
  border: 0.5px solid white;
  width: 100%;
  background-color: black;
  color: #fff;
  padding: 4% 0%;

  font-size: 0.9rem;
  margin: 2% auto auto auto;
  text-align: center;
  border-radius: 24px;
}

.homepage-signup-btn {
  width: 100%;
  border: 0.5px solid black;
  padding: 4% 10%;
  margin: auto auto 2% auto;
  background-color: fff;
  color: #000;
  text-align: center;
  border-radius: 24px;
  font-size: 0.9rem;
}

.homepage-icon {
  /* max-width: 11%;
	top: 10%;
	left: 5%;
	position: absolute;
	z-index: 1; */
  /* width: 50px; */
  width: 40px;
}

.homepage-bubbles {
  /* background-color: #000; */
  background-color: #0F1013;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.homepage-bubble {
  border: 2px solid black;
  position: absolute;
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  z-index: 0;

  object-fit: cover;
  object-position: 5% 0%;
  animation: rise 0s infinite ease-in;
}
.homepage-bubble:nth-child(1) {
  width: 270px;
  height: 270px;

  left: 0%;
  animation: rise 0s infinite ease-in;
  animation-duration: 7.5s;
  animation-delay: 0s;
}
.homepage-bubble:nth-child(2) {
  width: 270px;
  height: 270px;
  bottom: -300px;
  /* left: 11%; */
  left: 5%;
  animation-duration: 7.5s;
  animation-delay: 2s;
}
.homepage-bubble:nth-child(3) {
  width: 270px;
  height: 270px;
  bottom: -300px;
  /* left: 23%; */
  left: 8%;
  animation-duration: 7.5s;
  animation-delay: 3s;
}
.homepage-bubble:nth-child(4) {
  width: 270px;
  height: 270px;
  bottom: -300px;
  /* left: 40%; */
  left: 80%;
  animation-duration: 7.5s;
  animation-delay: 1s;
}
.homepage-bubble:nth-child(5) {
  width: 270px;
  height: 270px;
  bottom: -300px;
  /* left: 51%; */
  left: 85%;
  animation-duration: 7.5s;
  animation-delay: 3s;
}
.homepage-bubble:nth-child(6) {
  width: 270px;
  height: 270px;
  bottom: -300px;
  /* left: 63%; */
  left: 88%;
  animation-duration: 7.5s;
  animation-delay: 2s;
}
.homepage-bubble:nth-child(7) {
  width: 270px;
  height: 270px;
  bottom: -300px;
  /* left: 80%; */
  left: 90%;
  animation-duration: 7.5s;
  animation-delay: 0s;
  display: none;
}
.w-100 {
  width: 100%;
}

@keyframes rise {
  0% {
    bottom: -200px;
  }
  50% {
  }
  100% {
    bottom: 1080px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .homepage-bubbles {
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 100%;

    overflow: hidden;
    top: 0;
    left: 0;
  }
  .homepage-bubble {
    border: 2px solid black;
    position: absolute;

    width: 10px;
    height: 10px;
    background: #f1f1f1;
    border-radius: 50%;
    z-index: 0;

    object-fit: cover;
    object-position: 5% 0%;
    animation: rise 0s infinite ease-in;
  }
  .homepage-bubble:nth-child(1) {
    width: 100px;
    height: 100px;

    left: -50%;
    animation: rise 0s infinite ease-in;
    animation-duration: 7.5s;
    animation-delay: 0s;
  }
  .homepage-bubble:nth-child(2) {
    width: 100px;
    height: 100px;
    bottom: -300px;
    left: 1%;
    animation-duration: 7.5s;
    animation-delay: 2s;
  }
  .homepage-bubble:nth-child(3) {
    width: 100px;
    height: 100px;
    bottom: -300px;

    left: 7%;
    animation-duration: 7.5s;
    animation-delay: 4s;
  }
  .homepage-bubble:nth-child(4) {
    width: 100px;
    height: 100px;
    bottom: -300px;
    display: none;
    left: 24%;
    animation-duration: 7.5s;
    animation-delay: 0s;
  }
  .homepage-bubble:nth-child(5) {
    width: 100px;
    height: 100px;
    bottom: -300px;
    left: 35%;
    animation-duration: 7.5s;
    animation-delay: 3s;
  }
  .homepage-bubble:nth-child(6) {
    width: 100px;
    height: 100px;
    bottom: -300px;
    left: 63%;
    animation-duration: 7.5s;
    animation-delay: 2s;
  }
  .homepage-bubble:nth-child(7) {
    width: 270px;
    height: 270px;
    bottom: -300px;
    left: 80%;
    display: none;
    animation-duration: 7.5s;
    animation-delay: 0s;
  }
  /* .homepage-icon {
		max-width: 20%;
		top: 10%;
		left: 8%;
		position: fixed;
		z-index: 1;
	} */

  .homepage-btns {
    z-index: 2000;
    width: 80%;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 5%;
    bottom: 5%;
    position: fixed;

    text-align: center;
  }

  .mission_container { 
	  margin-top: 70%;
  }

  .navbar_container { 
	  width: 95%;
    padding-left: 1rem;
    grid-column-gap: 0px;
  }

  .navbar_link { 
	  font-size: 12px;
    margin-left: 10px;
    text-align: center;
  }

  .navbar_link.sign-up {
    text-align: center;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  .navbar_link.login {
    text-align: center;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  /* .navbar_link.pricing {
    display: none;
  } */

  .navbar_link.try {
    font-size: 16px !important;
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    /* background-color:#4285f4; */
    background-color: #2449fc;
    /* background-color: rgb(30, 35, 48); */
    border-radius: 15px;
    margin-left: 10px;
  }

  .navbar_link.demo {
    font-size: 16px !important;
    padding: 0.5rem 1rem;
    text-decoration: none;
    /* background-color:#4285f4; */
    background-color: rgb(239, 240, 236);
    /* background-color: rgb(30, 35, 48); */
    border-radius: 15px;
    margin-left: 10px;
  }
}