.search-input-wrapper {
	width: 100%;
	box-sizing: border-box;
	border: none;
	outline: none;
	background-color: #f1f1f1;
	margin: auto;
	height: 50px;
	display: flex;
	border-radius: 15px;
	overflow: hidden;
	padding: 0 18px;
}
.search-input-box {
	border: none;
	outline: none;
	height: 100%;
	color: #000;
	background: #f1f1f1;
	font-size: 18px;
	width: 90%;
}
.search-input-icon-btn {
	border: none;
	color: #000;
	width: 10%;
	background-color: #f1f1f1;
	text-align: right;
}
