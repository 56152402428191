

.outter-section-banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  /* box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08); */
  box-shadow: inset 0 0 100px -10px #000 !important;
  border: 1px solid #e6e7eb;
  /* padding-bottom: 0.325rem; */
  transition: all 0.15s ease-in-out;
  position: relative;
  overflow: hidden; /* Ensure the image doesn't overflow the div */
  min-height: 190px;
  max-height: 300px;
}


.outter-section-banner img{
  /* height: 99%; */
    left: 0;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    /* object-fit: cover; */
    object-position: 0% 5%;
    width: 100%;
    z-index: -1;
}

.banner-image {
    width: 100%;
    /* height: 300px; */
    background-position: center;
    position: relative;
  }

  /* .banner-image:before{
    background: linear-gradient(180deg,transparent 0%, #000 130%);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    bottom: 0;
    content: "";
    height: 5rem;
    left: 0;
    position: absolute;
    transition: background .8s ease-in-out;
    width: 100%;
    z-index: 2;
  }

  .banner-image::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
    z-index: -1;
} */

  @media screen and (max-width:600px){
    .outter-section-banner {
      min-height: 200px;
      width: 100%;
    }
    .banner-image {
      min-height: 200px;
      height: 100%;
    }
  
    .banner-title {
      left: 35% !important;
      font-size: .8rem !important;
      margin-top: -10.5% !important;
    }
  }

  /* .textBlock{
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: black;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    transform: translate(-50%, -50%);
  } */

  .banner-button-primary {
    color: #fff;
    background: #000000;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.75);
  }

.banner-title{
  text-align: center;
  position: absolute;
  left: 44%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  line-height: 1.375rem;
  margin: 0;
  max-width: 80%;
  overflow-wrap: break-word;
  font-weight: bold;
  color: white;
  text-decoration: none !important;
  margin-top: -3.5%;
}
  /* width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem; 
  display: block; 
  object-position:  0% 1%;
} */
