.creator-feed-grid-wrp {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: center;
  grid-gap: 25px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
  height: auto;
}

.creator-feed-card-video {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 45% 0%;
  border-radius: 0px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  background-color: #f1f1f1;

}

.creator-feed-card-video-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 45% 0%;
  border-radius: 0px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  background-color: #f1f1f1;
  /* margin-top: 100px; */

}

.creator-feed-card-video-carousel {

  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 45% 0%;
  border-radius: 0px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  background-color: #f1f1f1;
}

.creator-feed-card-video-carousel-image {

  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 2%;
  object-position: 45% 0%;
  border-radius: 0px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  background-color: #f1f1f1;
}

.card-image-top.creator-feed-card-video-carousel img {
  object-fit: contain;
  background-color: #f1f1f1;

}

.card-image-top.creator-feed-card-video-carousel-image img {
  object-fit: contain;
  background-color: #f1f1f1;

}

.card-image-top.creator-feed-card-video img {
  object-fit: contain;
  background-color: #f1f1f1;

}

.card-image-top.creator-feed-card-video-image img {
  object-fit: contain;
  background-color: #f1f1f1;

}

.creator-feed-image-wrapper-links.not-available {
  background-color: rgb(247, 247, 247);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10%;
  text-align: center;
  color: #3b3b3b;
  font-size: 0.9rem;
  height: 200px
}

.creator-feed-card-video.not-available {
  background-color: rgb(247, 247, 247);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10%;
  text-align: center;
  color: #3b3b3b;
  font-size: 0.9rem;
}

.creator-feed-caption-mobile {
  font-size: 1em;
  margin-top: 2%;
  padding-top: -2%;
  color: #000;
  max-height: 78px;
  overflow-y: hidden;
  margin-bottom: 0.165rem;
  position: relative;
}

.card {
  --bs-card-border-color: #fff;
}

.creator-feed-caption-mobile-active {
  font-size: 1em;
  padding-top: -2%;
  color: #000;
  overflow-y: hidden;
  margin-bottom: 0px;
}

.creator-feed-see-more-btn {
  color: rgba(12, 12, 12, 0.797);
  border: none;
  z-index: 1;
  cursor: "pointer";
  padding-top: 5px;
}

.creator-feed-div-not-found {
  display: inline-block;
  margin: 5% auto;
  color: #000;
  font-size: 2rem;
  text-align: center;
  width: 100%;
  height: 800px;
}

.creator-feed-caption-card {
  padding: 1px 10px;
  background: #f9f9f9;
  border-radius: 5px;
  font-size: 0.9rem;
}

.creator-feed-showMore-active {
  display: none;
}

.creator-feed-content-info {
  display: flex;
  font-size: 1rem;
  color: #000;
  align-items: center;
  height: 24px;
  margin-top: 4.4%;
  margin-bottom: 1%;
}

.creator-feed-content-info-sponsored {
  display: flex;
  font-size: 1rem;
  color: #000;
  align-items: center;
  height: 24px;
  margin-top: 10px;
}

.creator-feed-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creator-feed-content-date {
  font-size: 0.8rem;
  margin: 0;
  color: #000;
}

.creator-feed-content-sponsored {
  font-size: 0.8rem;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 2px;
}

.creator-feed-content-info a {
  background-color: #fff;
  color: #000;
}

.creator-feed-post-links {
  margin-left: auto;
  margin-right: 0;
  color: #000;
}

.creator-feed-ad-indicator {
  margin-left: auto;
  margin-right: 0;
  color: #000;
}

.creator-feed-username {
  color: #000;
  font-size: 0.6rem;
}

.creator-feed-card-video.hover-play {
  cursor: pointer;
  height: 500px;
}

.creator-feed-card-play-btn {
  color: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 2%;
  bottom: 2%;
  font-size: 2rem;
  display: block !important;
}

.creator-feed-card-play-btn-click {
  color: rgba(255, 255, 255, 0.9);
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.creator-feed-card-play-btn-click:hover {
  color: rgba(255, 255, 255, 0.7);
}

.creator-feed-card-video video {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 45% 0%;
  border-radius: 0px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  background-color: #f1f1f1;

}

@media screen and (max-width: 600px) {
  .creator-feed-content-info {
    margin-left: 2%;
    margin-right: 2%;
  }
}


.creator-feed-content-wrapper {
  display: flex;
  justify-content: center;
}

.creator-feed-grid-wrp{
  max-width: 80rem;
  transition: max-width 2s ease-in-out;
}

@media (min-width:1056px) and (max-width:1416px) {
  .creator-feed-grid-wrp{
    max-width: 60rem;
  }
}

@media (min-width:700px) and (max-width:1055px) {
  .creator-feed-grid-wrp{
    max-width: 39.5rem;
  }
}