.outer-container-youtube {
  width: 100%; /* Set the width to 60% */
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: justify;
  overflow-x: hidden;
}

.links-grid-container-youtube {
  display: flex; /* Arrange children in a row */
  flex-wrap: nowrap; /* Prevent wrapping to next line */
}

.creator-feed-image-wrapper-youtube {
  /* border:1px solid red; */
  flex: 0 0 auto;
  width: 250px;
  height: 200px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 15px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.creator-feed-card-youtube-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow:0 2px 0.75rem -0.25rem rgba(10,20,26,.08);
  border: 1px solid #e6e7eb;
  padding-bottom: .325rem;
  transition: all .15s ease-in-out;
  position: relative;
}

.template-shop-btn-youtube{
  /* border:1px solid purple; */
  width:200px;
  background-color: black;
  color: white;
  border:none;
  margin-top: 1%;

}
.template-shop-btn-youtube:hover{
  /* border:1px solid purple; */
  width:200px;
  background-color: grey;
  color: white;
  border:none;
  margin-top: 1%;
}
span .creator-feed-image-wrapper {
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  transition: all .15s ease-in-out;
  position: relative;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, .08);
  border-radius: 1rem;
  border: none !important;
  padding-bottom: 0px !important;
  background-color: none !important;
}
