.posts-caption {
  padding: 5px 5px;
  background: #fcfcfc;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #3b3b3b;
  text-align: justify;
  line-height: 1.1rem;
  margin-top: 8px;
  overflow: auto;
}
.posts-title-caption {
  font-size: 1rem;
  color: black;
  margin: 0;
}
.posts-caption-more {
  font-weight: bold;
  cursor: pointer;
  font-size: 0.8rem;
}
.posts-caption-more-btn {
  font-weight: bold;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: #fff;
  border: none;
}
.posts-comments {
  padding: 0px 5px;
  background: #fcfcfc;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #3b3b3b;
  text-align: justify;
  line-height: 1.1rem;
  margin-top: 8px;
}
.slick-dots li button {
  /* color: red !important; */
  background: black;
}
.ant-carousel .slick-dots-bottom {
  bottom: 0% !important;
}
@media screen and (max-width: 600px) {
  .feed-post .slick-dots-bottom {
    bottom: -.5%;
  }
  .creator-feed-card-video-carousel {
    /* margin-top: 30%; */
  }
  .posts-caption{
    margin-right: 2%;
    margin-left: 2%;
  }
}

.ant-carousel .slick-dots-bottom {
  /* bottom: -6.8%; */
}

/* .shop-btn {
  background-color: black;
  color: white !important;
  border:none
}
.shop-btn:hover {
  background-color: black !important;
  color: white !important;
  border:none
} */



.video-hover {
  position: relative;
  height: 100%;
}

.pause-overlay {
  position: relative;
}

.card-img {
  background-color: #f1f1f1;
}

.mt-3 {
  margin-top: 3.3%;
}

.shop-btn {
  font-size: 0.6rem;
  background-color: #000;
}

.ml-6 {
  margin-left: 6px;
}

.mt-1 {
  margin-top: 1%;
}

.perma-links {
  margin-right: 3px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: #000;
}

.shoppable-btn {
  font-size: 0.6rem;
  background-color: #000;
  margin-right: 3px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: #fff;
}

.feed-post {
  margin: 2%;
}