.account-social-content-connect {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 30px 0 30px 0;
	border-radius: 8px;
	overflow: hidden;
	background-color: #fcfcfc;
	border: solid #e8e8e8 1px;
}

.account-social-content-connect-row-platform {

	display: flex;
	align-items: center;
	/* justify-content: center; */
	/* padding: 15px 0; */
	/* cursor: pointer; */
	justify-content: space-between;
}

.account-social-content-connect-row-platform .platform-switch {
	/* align-items:end; */
	/* margin-left: 40%; */
	margin-left: 0%;
	display: flex;
	gap: 15px;
	margin-right: 15%;

}

.account-social-content-connect-row {

	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 15px 0; */
	padding: 20px;
	cursor: pointer;
}

.account-social-content-connect-row-cont {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 150px;
	gap: 12px;
}

.account-social-content-connect-row-cont p {
	margin: 0;
}

.account-social-content-connect-status {
	border: balck;
	color: black;
	border: solid black 1px;
	background-color: white;
	border-radius: 5px;
	padding: 3px 8px;
	display: flex;
	gap: 3px;
	font-size: 12px;
	align-items: center;
	justify-content: center;
}

.account-social-content-connect-status.active {
	border: solid rgb(177, 240, 199) 1px;
	background-color: rgb(177, 240, 199);
	color: rgb(54, 146, 82);
	height: 20px;
	width: 20px;
	padding: 0;
	border-radius: 100%;
	cursor: default;
}

.account-social-content-channelid-text {
	color: #0066cc;
	text-decoration: underline;
	margin: 20px 0 0 0;
	font-size: 0.9rem;
	cursor: pointer;
}

.account-social-content-channelid-ins {
	margin: 20px 0 20px 0;
}

.account-social-content-channelid-link {
	color: #0066cc;
	text-decoration: underline;
	margin: 0;
	cursor: pointer;
	background-color: transparent;
}

.account-social-content-channelid-link:hover {
	color: #0066cc;
	text-decoration: underline;
	background-color: transparent;
}

.spin-integration {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
}

.alert-margin {
	margin: 10px 0;
}

.mt-5 {
	margin-top: 5%;
}

.chevron-right {
	margin: 0 6px 0 0;
}

@media screen and (max-width: 600px) {

	.account-social-content-connect {
		width: 100%;
		margin: 30px 0;
	}

	.account-social-content-connect-row-platform {
		justify-content: flex-start;
		width: 65%;
		/* margin-left: 2%; */
	}

	.account-social-content-connect-row-platform .platform-switch {
		margin-left: 25%;
		margin-right: 0%;
	}

}



.creator-profile-social-links .account-social-content-connect {
	margin: 0;
	border: none;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background: transparent;
	gap: 5px;
	flex-wrap: wrap;
}

.creator-profile-social-links .account-social-content-connect .SocialLinksIcon {
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 10px 0;
	padding-top: 13px;
    padding-bottom: 8px;
	grid-gap: 5px;
	grid-row-gap: 10%;
	flex-wrap: wrap;
	padding-left: 0;
}

.creator-profile-social-links .account-social-content-connect .SocialLinksIcon .active-social-links {
	width: 35px;
	height: 35px;
	font-size: 1rem;
}

.creator-profile-social-links .add-button {
	padding: 0;
	/* padding-top: 2px; */
	border: none;
	border-radius: 30px;
	height: 42px;
	width: 42px;
	/* justify-content: center; */
	color: black;
	cursor: pointer;
}