.feed-tab-posts-table {
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
}

.feed-tab-post-row {
  display: flex;
  gap: 30px;
  cursor: pointer;
}

.feed-tab-post-row-grab {
  cursor: grab;
}

.feed-tab-row-media-bank {
  width: 30%;
  text-align: center;
  position: relative;
}

.feed-tab-row-media {
  width: 30%;
  text-align: center;
  position: relative;
  max-height: 400px;
  height: 100%;
}

.feed-tab-row-media-carousel {
  width: 95%;
  text-align: center;
  /* position: relative; */
  max-height: 300px;
}

.feed-tab-row-media-wrapper {
  width: 80%;
  height: 100%;
}

.feed-tab-row-media-wrapper-carousel {
  width: 100%;
  height: 70%;
}

.feed-tab-row-actions-mobile {
  display: none;
}

.feed-tab-row-tags {
  width: 40%;
}

.feed-tab-media {
  /* object-fit: cover; */
  object-position: 0% 5%;
  width: 100%;
  /* height: 90%; */
  border-radius: 1rem;
  object-fit: contain;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.feed-tab-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 30%;
  /* width: 32%; */
  gap: 15px;
  align-items: stretch;
  padding: 0 10px;
}

.feed-tab-buttons {
  width: 80%;
  color: black;
  border: solid black 1px;
  border-radius: 5px;
}

/* .css-dev-only-do-not-override-1oefhew.ant-btn.ant-btn-default {
	height: auto !important;
    white-space: normal !important;
} */

.search-container-tagInput {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: #f1f1f1;
  margin: auto;
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}

.tagInput {
  border: none;
  outline: none;
  height: 100%;
  color: #000;
  font-size: 15px;
  width: 100%;
  background: #f1f1f1;
  color: #000;
  padding: 0 10px;
}

.carousel-div {
  border: 1px solid red;
  height: 300px;
  justify-items: center;
}

.feed-tab-media-carousel {
  object-fit: contain;
  object-position: 0% 5%;
  width: 95%;
  height: 20%;
  /* margin-right: -2%; */
  border-radius: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.feed-tab-media-carousel-content {
  object-fit: contain;
  object-position: 0% 5%;
  width: 100%;
  height: 20%;
  /* margin-right: -2%; */
  border-radius: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: scroll;
}


.slick-dots li button {
  /* color: red !important; */
  background: black !important;
}

/* .feed-tab-media-carousel .slick-dots-bottom {
  bottom: -1%;
} */
.ant-modal-content .feed-tab-media-carousel .slick-dots-bottom {
  bottom: 1%;
}

.feed-tab-row-media-wrapper .slick-dots .slick-dots-bottom {
  bottom: -1%;
}

.ant-btn-default {
  white-space: nowrap;
  /* height: auto; */
}

.ant-btn-primary {
  white-space: normal !important;
  /* height: auto; */
  font-size: smaller;
  border: none;
  white-space: normal;
  word-break: break-word;
}

.ant-btn-primary a {
  text-decoration: none;
}

.feed-tab-media-carousel-content .slick-track {
  display: flex !important;
  width: 100% !important;
}

.feed-tab-media-carousel-content .slick-slide {
  width: 100% !important;
  flex: 0 0 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.feed-tab-media-carousel-content .slick-list {
  overflow: hidden;
}

/* @media screen and (min-width: 600px) {
	.feed-tab-media-carousel .slick-dots-bottom {
		bottom: -13%;
	}
} */
@media screen and (max-width: 365px) {
  .ant-btn-default {
    /* white-space: break-spaces; */
    height: auto !important;
  }

  .feed-tab-row-media {
    width: 100%;
    max-height: 450px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }

  .feed-tab-row-media-bank {
    width: 100%;
    /* max-height: 450px; */
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }
}

@media (min-width: 453px) and (max-width: 540px) {
  .creator-feed-content {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    text-align: left;
    width: 80% !important;
  }
}

@media screen and (max-width: 540px) {
  .feed-tab-media-carousel .slick-dots-bottom {
    bottom: -17%;
  }

  .feed-tab-post-row {
    gap: 30px;
    flex-direction: column;
    border-bottom: gainsboro 1px solid;
    padding-bottom: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .feed-tab-row-media {
    width: 100%;
    max-height: 380px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }

  .feed-tab-row-media-bank {
    width: 100%;
    /* max-height: 380px; */
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }

  .feed-tab-row-media-carousel {
    width: 100%;
    max-height: 300px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }

  .feed-tab-row-media-wrapper {
    width: 50%;
    height: 100%;
  }

  .feed-tab-row-actions-mobile {
    display: block;
    width: 50%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 0 0 0 10px;
  }

  .feed-tab-row-tags {
    width: 100%;
  }

  .feed-tab-actions {
    display: none;
  }

  /* .creator-feed-content {
    width: 100% !important;
  } */
  /* .feed-tab-drag-btn{
		border:1px solid black;
		width:5%;
		display: flex;
	} */
}

/* .feed-tab-draggable{
	display:inline-flex
} */

/* .feed-tab-post-row {

	opacity: 1;
	transition: opacity 0.3s ease-in-out;
  }
  
  .feed-tab-post-row-grab {
	cursor: grab;
  }
  
  .feed-tab-post-row:active,
  .feed-tab-post-row.dragging {
	opacity: 0.5; 
  }
   */

.feed-tab-post-row {
  /* Other styles */

  /* Transition for smooth animation */
  transition: opacity 0.3s ease-in-out;
}

.feed-tab-post-row-dragging {
  opacity: 0.5;
}

.ant-tabs-tab-disabled {
  color: grey !important;
  cursor: not-allowed;
}

a:hover {
  color: blue;
}

.upgrade-subscription {
  background-color: #2449fc;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  justify-content: end;
}

/* .slick-slide .feed-tab-media{
	max-height: 150px !important;
} */

.iconSize {
  font-size: 16px;
  margin: 0 7px 1px 0;
}

.text-area {
  width: 80%;
  margin-bottom: 2.7%;
  margin-left: 4%;
}

.feed-tab-creator-info {
  display: "flex";
  align-items: "center";
  justify-content: space-between;
}

.creator-feed-content {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
}

.creator-feed-content-date {
  text-align: left;
  /* flex: 1; */
}

.creator-feed-content-icon {
  margin-left: 6px;
  text-align: right;
  color: #000;
  /* flex: 0; */
}

.mh-350 {
  max-height: 350px;
}

.mh-300 {
  max-height: 300px;
}

.post {
  width: 80%;
  margin-bottom: 5%;
}

.image-table-name {
  font-weight: bold;
  margin-bottom: 3px;
  text-align: left;
  margin-left: .3rem;
}

.mt-7 {
  margin-top: 7.5%;
}

.image-table-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 10px;
}

.mb-4 {
  margin-bottom: 4%;
}

.label-modal {
  max-width: 100%;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.edit-product-modal {
  display: block;
  text-align: center;
  font-weight: bold;
}

.ti-pin {
  font-size: 0.9rem;
  /* margin: 0 7px 0px 0; */
  padding: 0;
  font-size: 1rem;
}

.manage-button-font {
  font-size: 0.82rem;
}

.display-flex {
  display: flex;
}

.mt-3 {
  margin-top: 3%;
}

.width-20 {
  width: 20%;
}

.tab-pane {
  display: flex;
  align-items: center;
}

.fa-lock {
  margin-right: 4px;
  color: grey;
}

.text-right {
  text-align: right;
}

.modal-text {
  margin-top: 2%;
  font-size: 1rem;
}

.br-5px {
  border-radius: 5px;
}

.content-table {
  margin-top: 4.5%;
  text-align: center;
}

.file-download-btn {
  background: lightgrey;
  cursor: default;
}

.bs-archive-fill {
  font-size: 16px;
  margin: 0 7px 1px 0;
}

.shop-link {
  word-wrap: break-word;
  font-size: 0.8rem;
}

.product-bank-add-btn {
  background-color: #000;
  color: #fff;
  height: 40px
}

.product-bank-add-btn-icon {
  background-color: #000;
  color: #fff;
  height: 40px !important;
  min-width: 40px;
  border-radius: 50%;
}

@media (min-width:768px) and (max-width:991px) {
  .feed-tab-actions {
    width: 35%;
  }
}

.feed-tab-media-carousel .feed-tab-media-check.square-image {
  margin-bottom: 4%;
}



.no-image-text {
  align-self: center;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  color: white;
  flex-direction: column;
  text-align: center;
  border-radius: 1rem
}

:where(.css-dev-only-do-not-override-f6dewv).ant-carousel .slick-dots li.slick-active::after {
  background-color: #000;
}