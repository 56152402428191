/* ContentCharts.css */
.filter-section {
  margin: 10px 0;
}

.filter-section p{
  margin-bottom: 0;
}

.segmented-container {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent line breaks */

  padding: 10px;
  /* background-color: #f0f2f5; Adjust background color */
  border-radius: 5px;
  /* Adjust border radius */
  background-color: none;
  display: flex;
  gap: 10px;
  padding-left: 0%;
}

/* .segmented-container .ant-segmented-item {
  display: inline-block; 
  margin-right: 10px; 
} */
.segmented-container .ant-btn-default {
  background: white;
  border: solid black 1px;
  border-radius: 9px;
  padding: 4px 12px;
  cursor: pointer;
  white-space: nowrap;
  font-size: .9rem;
}

.ant-modal-footer .ant-btn-default{
  box-shadow: 0 3px 0 rgb(223 218 218);
}
.analytics-chartClicks-thumbnail-div {
  /* max-width: 450px;
  height: 100px; */
}

/* .analytics-chartClicks-thumbnail-div img {
	max-width: 350px;
} */

.analytics-table {
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 10px;
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.sup-analytics-grid-item {
  display: grid;
  grid-template-columns: 90% 10%;
}



.shoppable-btn:hover {
  color: #fff !important;
  border-color: #0d0d0d !important;
  background: #000 !important;
}

@media screen and (max-width: 600px) {
  .analytics-table {
    grid-template-columns: repeat(1, 1fr);
  }
}

.analytics-chartClicks-thumbnail {
  width: 15%;
  max-width: 25%;
  border-radius: 5px;
}

.ant-segmented-item {
  border: 1px dashed;
  margin: 2px;
  background-color: none;
}

.ant-segmented-group {
  background-color: none;
}

.ant-segmented {
  background-color: none;
}

.tabAnchor a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.recharts-layer .recharts-cartesian-axis-tick {
  text-wrap: wrap;
  width: 10% !important;
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.analyticsTable {
  border-bottom: 1px solid #42b0ba;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  max-height: 350px;
  max-width: 450px;
}

.custom-tooltip {
  z-index: -1;
  background: white;
  border: 1px solid #d5d5d5;
  border-radius: 5;
  line-height: 20px;
  width: auto;
}

.loaderMyLink {
  margin-top: 2%;
  margin-bottom: 4%;
  width: 100%;
  height: 100%;
}

.agchartSpin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.loadingPageAnalytics {
  margin-top: 2%;
  margin-bottom: 4%;
  /* border: 1px solid #f1f1f1; */
  width: 96%;
  /* margin-left: 3%; */
}

.fullDiv {
  width: 100%;
  height: 100%;
}

.clicks {
  display: flex;
  justify-content: center;
  margin-top: 2%
}

.clicks div {
  margin-right: 20px;
}

.mt-2 {
  margin-top: 4px;
}

.social {
  margin: 2%;
  padding: 3px;
}

.clicks-color {
  color: #42b0ba;
}

.p-3px {
  padding: 3px;
}

.mt-15px {
  margin-top: 15px;
}

.mt-5 {
  margin-top: 5%;
}

.user-clr {
  color: #8685EF;
}

.search-clr {
  color: #404040;
}

.clicks-clr {
  color: #00C6CF;
}

.p-3px {
  padding: 3px;
}

.m-2 {
  margin: 2%;
}

.mr-20px {
  margin-right: 20px;
}


.right-aligned {
  text-align: right;
  /* Align text to the right */
  margin: 0;
  /* Reset margin if needed */
  padding: 0;
  /* Reset padding if needed */
}