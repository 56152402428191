.error-page { 
	height: 100%;
    width: 100%;
    position: absolute;
    background-color: #0F1013;
}

.error-page-back-btn { 
	color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    /* background-color: #4285f4; */
    background-color: #2449fc;
    /* background-color: rgb(30, 35, 48); */
    border-radius: 15px;
    margin-left: 10px;
	border: none;
	font-size: 18px;
}

.error-page-header {
	/* margin: 3% 5%; */
}
.error-page-error-msg {
	font-size: 1.65rem;
	color: white;
	padding: 15px;
	position: absolute;
	top: 45%;
	left: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
}
/* .error-page-back-btn {
	background-color: #404040;
	border: none;
	border-radius: 8px;
} */
.error-page-back-btn a {
	text-decoration: none;
	color: white;
}
.error-page-back-btn a:hover {
	color: white;
}
