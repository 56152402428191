.uptodate-wrapper {
  /* border: 1px solid black; */
  max-width: 45rem !important;
  /* Initial width 100% */
  /* width:788px; Maximum width allowed */
  margin: 0 auto;
  /* Center the wrapper horizontally */
  /* border:1px solid black; */
  margin: 0 auto;
  color: #fff !important;
  border: 1px solid #e6e7eb;
  border-radius: 1rem;
  padding: 10px 30px 10px 30px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  transition: max-width 1.5s ease-in-out;
}

.uptodate-wrapper body {
  border-top: 1mm solid #d1e2de;
}

@media (max-width: 889px) {
  .uptodate-wrapper {
    width: 100%;
    /* Set width to 800px between 800px and 889px */
    /* border:1px solid black; */
    border: none;
    border-radius: none;
    padding: 0px !important;
    box-shadow: none;
    padding: 0 !important;
    /* margin-top: 10px !important; */
    /* margin-top: -10px !important; */
  }
}



.template-shop-btn {
  /* background-color: black;
    color: white; */
  width: 100%;
}

.template-shop-btn :hover {
  background-color: grey;
  color: white;
  width: 100%;

}

.mainDiv {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 10px;
}

.component-name {
  margin-right: auto;
  color: black;
  line-height: 1.3;
  padding-right: .5rem;
  margin-bottom: 0rem;
  font-weight: 700;
  font-size: 1.25rem;
  width: 75%;
}

.component-name-individual {
  margin-right: auto;
  color: black;
  line-height: 1.3;
  padding-right: .5rem;
  margin-bottom: 0rem;
  font-weight: 700;
  font-size: 1.25rem;
  /* width: 75%; */
}


.link-url {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.youtube-link-position {
  position: relative;
  /* align-self: center; */
}

.hourglassSplit {
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.product-name {
  color: black;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-left: .3rem;
  white-space: normal;
  word-break: break-word;
  text-wrap: balance;
  width: 200px;
  text-align: left;
}

.productName-card {
  color: black;
  align-content: center;
  text-decoration: none;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  margin-left: .3rem;
}

.video-product-name {
  color: black;
  align-content: center;
  justify-content: center;
  text-decoration: none;
}

.skeleton {
  width: 180px;
  height: 300px;
  cursor: pointer;
  border-radius: 15px;
}

.data-skeleton {
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  width: 100%;
  padding: 10px;

  border-radius: 10px;
}

.banner-skeleton {
  margin: 0 auto;
  margin-top: 8%;
  width: 100%;
  height: 300px;
}

.bannerSkeleton {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 15px;
  padding: 2%;
}

.linksTemplate {
  width: 170;
  border: 1px solid #e8e8e8;
  border-radius: 4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.google-auth {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  margin-top: 30px;
  font-size: 1.2rem;
}

.m-0 {
  margin: 0;
}

.tooltip-style {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header-text {
  font-size: 12px;
  margin-bottom: 1%;
}

.sign-up {
  background: black;
  color: white;
}

.mt-minus2 {
  margin-top: -2%;
}

.link-caption {
  margin-bottom: 8;
  font-weight: bold;
}

.link-template-img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.p-16 {
  padding: 16;
}

.link-skeleton {
  margin-top: 2%;
  width: 100%;
  height: 120px;
}

.loading-skeleton {
  margin: 0 auto;
  text-align: center;
}

.m-10px {
  margin: 10px;
}

.mb-20px {
  margin-bottom: 20px;
}

.detail-margin {
  margin: 0 0 40px 0;
}

.pagination-data-change {
  margin: 10px;
  display: block;
  text-align: center;
}

.mt-8 {
  margin-top: 8%;
}

.mt-4 {
  margin-top: 4%;
}

.end-msg {
  text-align: center;
  margin-top: 10%;
}

.item-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  /* grid-template-columns: 50% 50%; */
}




.item-grid a {
  text-decoration: none;
}

.item-grid .creator-feed-image-wrapper-pod .creator-feed-image-wrapper-podcast,
.item-grid .creator-feed-image-wrapper-links {
  margin-right: 0;
}

.creator-feed-image-wrapper-links.youtube-feed-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 350px; */
}

.creator-feed-image-wrapper-links.media-feed-wrapper {
  /* height: 200px; */
}

.media-feed-height {
  height: 200px !important;
  padding: 0 0 0.325rem 0 !important;
  border-radius: 15px !important;
}

.youtube-feed-height {
  /* height: auto !important; */
}

.item-grid .creator-feed-image-wrapper-pod {
  width: 100%;
}

.component-name:hover {
  cursor: pointer;
}

.component-name-individual:hover {
  cursor: pointer;
}

.feed-back-icon {
  color: #000;
  cursor: pointer;
  background-color: #e6e7eb;
}

.uptodate-wrapper a {
  text-decoration: none;
}

.square-wrapper {
  aspect-ratio: 1/1;
  position: relative;
  text-align: center;
  /* width: 100%; */
  /* overflow: hidden; */
}

.square-wrapper .square-image,
.square-wrapper .square-video,
.square-wrapper video {
  /* width: 100%; */
  height: 200px;
  object-fit: cover;
}

.square-wrapper .template-shop-btn-links {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 2;
}

.square-wrapper.not-available {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f5f5;
  padding: 1rem;
}

@media (max-width: 768px) {

  .item-grid .creator-feed-image-wrapper-links,
  .item-grid .product-name,
  .item-grid .template-shop-btn-links {
    width: 100% !important;
  }

  .item-grid .creator-feed-image-wrapper-links {
    height: auto;
  }

}

.card-image-individual {
  height: 350px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 8px;
  border-radius: 8px;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);
  padding-bottom: 0.325rem;
}

.video-height-individual {
  margin-bottom: 8px;
  height: 350px;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .uptodate-wrapper .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  }


  .video-height-individual {
    height: 350px;
  }

  .card-image-individual {
    object-fit: cover;
  }
}


@media (max-width: 450px) {
  .product-name {
    width: auto;
  }
}

.join-button {
  padding: 0.75rem 1.5rem;
  background-color: white;
  color: black;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  align-items: center;
  transition: background-color 0.2s;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 1.2;
  display: block;
  text-align: center;
}

.join-button:hover {
  background-color: #f9fafb;
}

.join-button>*+* {
  margin-left: 0.25rem;
}

.join-button__logo {
  width: 1rem;
  height: 1rem;
}

.join-button__placeholder {
  color: #4a4a4a;
  font-weight: 600;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 2px;
  transition: border-color 0.2s ease;
}

.join-button__placeholder:hover {
  border-bottom-color: #4a4a4a;
}


/* Mobile styles (screens smaller than 768px) */
@media screen and (max-width: 767px) {
  .join-button {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    width: 100%;
    justify-content: center;
  }

  .join-button__logo {
    width: 0.875rem;
    height: 0.875rem;
  }

  .join-button>*+* {
    margin-left: 0.20rem;
  }
}

/* Extra small screens (screens smaller than 375px) */
@media screen and (max-width: 374px) {
  .join-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.6875rem;
  }

  .join-button__logo {
    width: 0.75rem;
    height: 0.75rem;
  }

  .join-button>*+* {
    margin-left: 0.15rem;
  }
}


.search-container-stayuptodate {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: #f1f1f1;
  margin: auto;
  /* height: 75px; */
  height: 70px;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 15px;
}