*,
*::before,
*::after {
	box-sizing: inherit;
}

:where(.css-dev-only-do-not-override-1oefhew).ant-tabs-top >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1oefhew).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1oefhew).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1oefhew).ant-tabs-bottom >div>.ant-tabs-nav::before {
	border-bottom: 3px solid rgba(5, 5, 5, 0.06) !important;
}

:where(.css-dev-only-do-not-override-1oefhew).ant-tabs-card >.ant-tabs-nav .ant-tabs-tab, :where(.css-dev-only-do-not-override-1oefhew).ant-tabs-card >div>.ant-tabs-nav .ant-tabs-tab {
	transition: none;
}

html {
	box-sizing: border-box;
	overflow-x: hidden;
	scroll-behavior: smooth;
}
.login-app {
	display: none;
}
html body {
	padding: 0;
	margin: 0;
}
#app-wrapper {
	width: 90%;
	margin: 3% 5%;
		/* width: 90%;
		margin-top: 3%;
		margin-left: 3%;
		margin-right: 3%;
		justify-content: flex-end; */
		/* margin: 3% 5%; */
	
}

html {
	overflow-y: scroll;
}

body {
	height: 100vh;
}

.white-spinner .ant-spin-dot-item {
	background-color: white;
}
.white-progress.ant-progress.ant-progress-circle .ant-progress-text {
	color: white;
}

.btn-primary {
	--bs-btn-color: #000;
	--bs-btn-bg: #fff;
	--bs-btn-border-color: #fff;
	--bs-btn-hover-color: #000;
	--bs-btn-hover-bg: #fff;
	--bs-btn-hover-border-color: #fff;
	--bs-btn-focus-shadow-rgb: 49, 132, 253;
	--bs-btn-active-color: #000;
	--bs-btn-active-bg: #fff;
	--bs-btn-active-border-color: #fff;
}

h2 {
	display: block;
	font-size: 1.5em;

	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
	color: black;
	-webkit-margin-before: 0.25rem;
	-webkit-margin-after: 1rem;
}

.row {
	display: flex;
	height: 100%;
}

body {
	font-family: circular, Helvetica Neue, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;

	color: #ededed;
}

.row {
	display: flex;
}

.dash-row {
	display: grid;
	grid-template-columns: 90% 10%;
	padding-top: 0.67rem;
	padding-bottom: 2rem;
}
.dash-row .dashboard-img {
	margin-right: 2%;
	float: left;
	box-sizing: border-box;
	position: relative;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	margin-top: 3.5px;
}
.img-div {
	float: right;
}
.feed-loader {
	margin-left: 48%;
	margin-top: 0%;
}

.bubble-white-button {
	border: 1px solid white;
	background-color: white;
	border-radius: 5px;
	color: #404040;
	font-size: 1rem;
	padding: 10px 26px;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 14px;
}
.bubble-white-button:hover {
	background-color: rgb(249, 249, 249);
	color: #151515;
}
.bubble-white-button:disabled {
	background-color: rgb(140, 140, 140);
	pointer-events: none;
	border: solid transparent 1px;
}

.bubble-black-button {
	border: 1px solid white;
	background-color: #404040;
	border-radius: 5px;
	color: white;
	font-size: 1rem;
	padding: 10px 26px;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 14px;
}
.bubble-black-button:hover {
	background-color: #5c5c5c;
}
.bubble-black-button:disabled {
	background-color: rgb(140, 140, 140);
	pointer-events: none;
	border: solid transparent 1px;
}

@media screen and (max-width: 640px) {
	.dash-row {
		height: 100px;
	}
	.bubble-white-button {
		width: 100%;
	}
	.bubble-black-button {
		width: 100%;
	}
}

.privacy{
	color: black;
	
}
.privacy-header{
	margin:3% 5%
}
.privacy-nav-title{
	color: #000;
	text-decoration: none;
	background-color: #fff;
}
.privacy-nav-title a{
	color: #000;
	text-decoration: none;
	background-color: #fff;
	font-weight: bolder;
}
.footer{
	height:100px;
	background-color:#E9EAEC
}
.footer p{
	color: #E9EAEC;
}
.privacy a{
	color:black;
	background-color: #fff;
	text-decoration: none;
}
/* 
------------------------------------------------------ styles to clean ------------------------------------------------------

future work:
the styles below have to be reviewed in the future, in this refactor I didn't have time to move them all to new files or delete them if they are not needed

*/

.dashboard {
	width: 90%;
	background-color: white;
	color: black;
	margin-left: 5%;
	margin-right: 5%;
	margin-top: 3%;
	margin-bottom: 3%;
}
.dashboard-mobile-show {
	display: none;
}

.dashboard-grid-item {
	border-radius: 10px;
	background: #f1f1f1;
	padding: 20px;
	font-size: 1.25rem;
}
.dashboard-grid-item p {
	margin: 0;
}

:where(.css-dev-only-do-not-override-1i536d8).ant-tooltip .ant-tooltip-inner {
	min-width: 32px;
	min-height: 32px;
	padding: 6px 8px;
	color: #fff;
	text-align: start;
	text-decoration: none;
	word-wrap: break-word;
	background-color: rgba(0, 0, 0, 0.85);
	border-radius: 6px;
	box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
}

hr {
	margin-left: 12px;
	margin-right: 12px;
	width: 600px;
}

.analytics-titles {
	padding-top: 1.25rem;
	display: grid;
	/* grid-template-columns: repeat(auto-fill, 350px); */
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	grid-template-columns: 50% 50%;

	padding-bottom: 1rem;
}
.left-panel {
	display: inline-block;
	width: 25%;
}

.right-panel {
	display: inline-block;
	width: 75%;
}
.drawer-menu {
	background: #f5f2f2;
	color: #000;
	border-radius: 10px;
}
.loader {
	height: 350px;
	margin: auto;
	margin-top: 160px;
}
.drawer {
	height: 90%;
	width: 100%;
	font-size: 1.3rem;
	align-items: center;
	margin-top: 4.75rem;
}

.css-1ontqvh::-webkit-scrollbar {
	display: none;
}
#listButton {
	padding: 2%;
	font-size: 1.5rem;
	margin-top: 5%;
	margin-bottom: 5%;
	justify-content: center;
	border-radius: 10px;
	text-align: center;
	/* white-space: nowrap; */
}
#listButton:hover {
	background-color: #716d6d;
	color: #fff;
	border-radius: 10px;
}

.dash-head {
	display: grid;
	grid-template-columns: 90% 10%;
	padding-top: 0.67rem;
	padding-bottom: 2rem;
}

@media (max-width: 767.98px) {
	hr {
		margin-left: 0px;
		margin-right: 0px;
		max-width: 600px;
	}

	.left-panel {
		width: 100%;
	}

	#listButton {
		/* font-size: 1.25rem; */
		font-size: 1rem;
		padding: 10px;
		margin: 0px;
		border-radius: 10px;
		white-space: nowrap;
	}

	.selectedTab {
		border: 1px solid black;
	}
	.drawer {
		margin-top: 0rem;
	}
	.drawer-menu {
		background: #f5f2f2;
		color: #000;
		border-radius: 10px;
	}

	#listButton:hover {
		background-color: #716d6d;
		color: #fff;
		border-radius: 10px;
	}

	.row {
		--bs-gutter-x: 0rem;
	}

	.right-panel {
		width: 100%;
		padding-top: 10px;
	}
	.analytics-titles {
		justify-content: center;
	}
	.dashboard-mobile-hide {
		display: none;
	}
	.dashboard-mobile-show {
		display: block;
		margin-top: 1rem;
	}
	#app-wrapper {
		margin-top: 5%;
		margin-bottom: 5%;
	}

	.dashboard {
		margin-top: 5%;
		margin-bottom: 5%;
	}
}
.loader {
	height: 550px;
}

.dropdown {
	height: auto;
	margin-left: 2%;
}
#drop {
	margin-left: 1.7%;
}
.push-btn {
	background-color: #404040;
	color: #fff;
	margin-right: 3%;
	border: 2px solid #404040;
	border-radius: 10px;
	cursor: pointer;
	outline: none;
	padding: 12px;
	font-size: 1.25rem;
	width: 200px;
	margin-bottom: 25px;
}
.push-btn:disabled {
	background-color: lightgrey;
	color: #0c0a0a;
	text-align: center;
	border: 1px solid #080606;
	border-radius: 10px;
	cursor: not-allowed;
	outline: none;
	padding: 12px;
	margin-bottom: 25px;
	font-size: 1.25rem;
	width: 200px;
}
.spin-btn {
	background-color: lightgrey;
	color: #0c0a0a;
	text-align: center;
	margin-right: 3%;
	width: 200px;
	border: 1px solid #080606;
	border-radius: 10px;
	cursor: not-allowed;
	outline: none;
	padding: 12px;
	margin-bottom: 25px;
	font-size: 1.25rem;
}

.list {
	margin-left: -12px;
	margin-top: 18px;
}
hr {
	max-width: 600px;
}
.table {
	margin-left: -8px;
	margin-top: 10px;
}
.dashboard-mobile-show {
	display: none;
}
.thumbnail-div {
	width: 100%;
	height: 100%;
	text-align: center;
	position: relative;
	overflow: hidden;
	width: 300px;
	height: 300px;
	border-radius: 10px;
}
.thumbnail-div-mobile {
	margin-top: 2%;
	max-width: 40%;
	text-align: center;
	margin-left: 10%;
}
.thumbnail-img {
	object-fit: cover;
	object-position: 5% 0%;
	width: 100%;
	height: 100%;
}
.thumbnail-video {
	object-fit: cover;
	object-position: 5% 0%;
	width: 100%;
	height: 100%;
}
.thumbnail-video-mobile {
	background-size: cover;
	width: 43%;
}
.thumbnail {
	width: 33%;
}
.thumbnail-mobile {
	max-width: 43%;
}
.postClicks-thumbnail-div {
	width: 100%;
}
.postClicks-thumbnail {
	max-width: 50%;
}
.column a {
	background-color: #fff;
	color: #000;
}
.column a:hover {
	background-color: #fff;
	color: blue;
}
.index-btn {
	margin-bottom: 10%;
	background-color: #404040;
	color: #fff;
	border-radius: 4px;
	border: none;
}
:where(.css-dev-only-do-not-override-1i536d8).ant-tooltip .ant-tooltip-inner {
	min-width: 32px;
	min-height: 32px;
	padding: 6px 8px;
	color: #fff;
	text-align: start;
	text-decoration: none;
	word-wrap: break-word;
	background-color: rgba(0, 0, 0, 0.85);
	border-radius: 6px;
	box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
}
hr {
	margin-left: 12px;
	margin-right: 12px;
	width: 600px;
}
.nav {
	margin-left: 12px;
	margin-right: 12px;
	width: 97%;
}
.css-1uwabd6.Mui-selected {
	border-radius: 10px;
	background-color: #716d6d !important;
	color: white;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected { 
	border-radius: 10px;
	background-color: #716d6d !important;
	color: white;
}

#slider {
	width: 90%;
	height: 10px;
}
.slider-input {
	display: flex;
	align-items: center;
}
.min-range {
	color: grey;
	margin-top: 2.8%;
	font-size: 14px;
	padding-right: 5px;
}
.max-range {
	color: gray;
	margin-top: 2.7%;
	font-size: 14px;
	float: right;
	margin-left: 3%;
}
.tab {
	border-bottom: 1px solid gray;
	border-top: none;
	border-left: none;
	border-right: none;
	color: #000;
}
.end-message {
	text-align: center;
	color: #000;
}
.font-1 {
	font-size: 1rem;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.date-scroll::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	}

	.css-h4y409-MuiList-root::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	}

	.nav {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}
	.left-panel {
		width: 100%;
	}
	.css-1ontqvh {
		display: grid;
		grid-template-columns: auto auto auto auto auto;
		grid-column-gap: 10px;
		overflow-x: scroll;
		grid-auto-flow: column;
		background-color: white;
	}

	.css-h4y409-MuiList-root { 
		display: grid;
		grid-template-columns: auto auto auto auto auto;
		grid-column-gap: 10px;
		overflow-x: scroll;
		grid-auto-flow: column;
		background-color: white;
	}

	.css-1tpp0wj {
		background-color: #f1f1f1;
		border-radius: 10px;
	}

	.css-1nxmd3h-MuiListItem-root { 
		background-color: #f1f1f1;
		border-radius: 10px;
	}

	.analytics-titles {
		justify-content: center;
		grid-template-columns: 1fr;
	}
	.dashboard-mobile-hide {
		display: none;
	}
	.dashboard-mobile-show {
		display: block;
		margin-top: 1.5rem;
	}
	.val {
		padding-top: 1.25rem;
		justify-content: center;
	}
	.controls {
		justify-content: center;
	}
	.push-btn-div {
		text-align: center;
		padding-bottom: 2rem;
	}
}
.feed-no-user-msg{
  color:#000;
  font-size: 1.5rem;
  text-align: center;
  height:400px;
  margin-top: 6%;
}
.twitter-icon {
	height: 18px;
	margin-left: 6px;
}
.index-1 {
	z-index: -1;
}
.header-component {
	display: flex;
	gap: 25px;
	align-items: center;
	justify-content: center;
	color: black;
}

.drag-icon-border {
	border: 1px solid black;
	padding: 8px;
}

.table-border {
	border-collapse: collapse;
	width: 100%;
	color: black;
}
.fullDiv{
	width: 100%;
	height: 100%;
}
.w-100 {
	width: 100%;
}
.display-block {
	display: block;
}

.extra-component {
		display: flex;
		gap: 25px;
		align-items: center;
		justify-content: center;
		color: black;
}