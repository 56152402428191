.feed-tab-posts-table-check {
    border: 1px solid red;
    display: flex;
    overflow: auto;
    /* white-space: nowrap; */
    padding-bottom: 10px;
}

.feed-tab-post-container-check {
    border: 1px solid purple;

}

.feed-tab-post-container p {
    white-space: break-spaces;
    width: 85%;
    height: auto;
    overflow: hidden;
}

.feed-tab-post-container input {
    width: 85%;
}

.feed-tab-media-wrapper button {
    display: block;
    margin: auto;
    align-items: center;
    margin-bottom: 5px;
}

.carousel-content-remove-btn {
    background-color: #f16262;
    color: white !important;
}

.carousel-content-remove-btn:hover {
    border-color: black;
    color: white;
    background-color: #f16262 !important;
    background: #f16262 !important;
}

.carousel-content-add-btn:hover {
    border-color: black;
    color: white !important;
    background-color: black !important;
    background: black !important;
}

.carousel-content-add-btn {
    background-color: black;
    color: white;
}

.feed-tab-media-wrapper img {
    width: 100%;
    height: 250px;
    min-width: 100px;
    min-height: 250px;
    max-height: 350px;
    background-color: #e6e7eb;
    border-radius: 1rem;
    object-fit: cover;
    border-radius: 1rem;
    border: 1px solid #e6e7eb;
    /* padding-bottom: 0.325rem; */
}

.addedSvg {
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
}

/* .addedSvg .feed-tab-post-container .feed-tab-media-wrapper img{
    width: 100%;
    height: 200px;
    object-fit: inherit;
    border-radius: 1rem;
    border: 1px solid #e6e7eb;
    padding-bottom: 0.325rem;
} */

.feed-tab-media-wrapper video {
    /* width: 70%;
    height: 80%;
    margin: auto;
    align-items: center;
    border-radius: 1rem;
    white-space: nowrap;
    overflow-y: scroll; 
    display: block; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    border: 1px solid #e6e7eb;
    /* padding-bottom: 0.325rem; */
    min-width: 100px;
    min-height: 100px;
    max-height: 350px;
}

.feed-tab-media {
    /* width: 100%;
    height: 100%;
    object-fit: inherit;
    border-radius: 1rem;
    border: 1px solid #e6e7eb;
    padding-bottom: 0.325rem; */
    border-radius: 5px;
    /* height: 90%; */
    object-fit: cover !important;
    object-position: 5% 0;
    width: 100%;
}

.feed-tab-media-check {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    border: 1px solid #e6e7eb;
    /* padding-bottom: 0.325rem; */
}

.checkbox {
    margin-bottom: 4%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
}

.feed-tab-posts-container {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;
    white-space: nowrap;
    overflow-y: scroll;
    border-radius: 1rem;
    gap: 5px;
}

.skeletonLoader {
    height: auto;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    width: 100%;
    vertical-align: top;
}

.videoMedia {
    max-height: 300px;
    white-space: nowrap;
    overflow-y: scroll;
    border-radius: 1rem;
}

.skeletonDiv {
    margin: 0 auto;
    margin-bottom: 2%;
    width: 100%;
    height: 160px;
    margin-right: 4%;
}

.skeletonStyle {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 15px;
    padding: 2%;
}

.addedContent {
    border: 1.5px dashed #c9ccd1;
    margin-bottom: 5%;
    padding: 2%;
    border-radius: 5px;
}

.addedContentTitle {
    font-weight: 500;
    color: grey;
}

.margin-table {
    margin: 0 0 20px 0;
}

.pagination {
    margin: 10px;
    display: block;
    text-align: center;
}

.feed-wrapper {
    display: inline-block;
    margin-right: 10px;
    width: 140px;
    vertical-align: top;
}

.h-255 {
    height: 255px;
}

.mt-7 {
    margin-top: 7.5%;
}

.feed-post-wrapper {
    flex: 0 0 auto;
    margin-right: 0px;
    width: 140px;
}

.shop-btn {
    margin-top: 4px;
    display: flex;
    margin-right: 10px;
    width: 140px;
    vertical-align: top;
}

.tag-style {
    margin-top: 2%;
    margin-left: 2%;
    font-size: 0.8rem;
}

@media screen and (max-width: 540px) {
    .feed-tab-media {
        width: 100%;
    }
}

.feed-tab-media-wrapper .h-255 video {
    /* height: 255px !important; */
}