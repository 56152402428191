.account-value-input {
  width: 270px;
  border: 1px solid #ccc;
}

.account-edit-btn-name,
.account-edit-btn-phn,
.account-edit-btn-email,
.account-done-btn-name,
.account-done-btn-phn,
.account-done-btn-email {
  border: none;
  margin-left: 10px;
  border-radius: 40px;
  padding: 1px 4px 3px 4px;
}

.account-done-btn,
.account-edit-btn {
  border: none;
  margin-left: 1%;
  border-radius: 40px;
  height: fit-content;
  width: fit-content;
}

.account-values-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, 350px); */
  /* grid-template-columns: repeat(auto-fill, 385px); */
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  /* padding-top: 1.25rem; */
  padding-top: .6rem;
  padding-bottom: 2rem;
  /* width: 50%; */
  grid-template-columns: 50% 50%;
}

.account-grid-item {
  border-radius: 10px;
  background: #f1f1f1;
  padding: 20px;
}

.account-grid-item-key-span {
  display: flex;
}

.account-grid-item-key {
  font-size: 1.25rem;
}

/* .account-values-profiles{
  padding-top: 1.25rem;
  padding-bottom: 0rem;
  padding-right: 2.7rem;
} */
.account-grid-item-key-status-btn {
  background-color: #e0dfdf;
  color: grey;
  border: 0.5px solid grey;
  border-radius: 10px;
  font-size: 0.8rem;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
}

.account-grid-item-key-status-btn.active {
  color: green;
  border: 0.5px solid green;
}

.account-grid-item-value {
  font-size: 1rem;
}

.otp-input {
  height: 2.1rem;
  background-color: #f2f2f2;
  margin-top: 2%;
  border: none;
  border-radius: 16px;
  width: 70%;
  text-align: center;
}

.modal-verify-btn {
  width: fit-content;
  margin-left: 5%;
  border: none;
  border-radius: 5px;
  background-color: #414141;
  color: #fff;
  border: 1px solid #414141;
}

.account-links {
  margin-top: -2%;
  width: 100%;
  font-size: 1.5rem;
}

.account-social-logins-heading {
  /* margin-top: -2%; */
  width: 100%;
  font-size: 1.5rem;
  /* display: flex; */
}

.account-add-btn {
  border: none;
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  margin-right: 1%;
}

.account-add-btn-icon {
  height: 25px;
}

.verified-text {
  margin-top: 2%;
  color: rgb(32, 131, 32);
}

.account-modal {
  top: 40%;
}

.account-modal .ant-modal-close-icon {
  /* display: none; */
}

.account-modal .ant-btn-default {
  display: none;
}

.ant-btn .ant-tabs {
  font-family: circular, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.verify-btn {
  /* color: rgb(243, 84, 84);
  border: none;
  background-color: #f1f1f1;
  margin-top: 2%;
  font-size: 1rem; */

  color: white;
  margin-top: 2%;
  /* font-size: 1rem; */
  /* border: none; */
  border-radius: 5px;
  /* padding: 7px; */
  background-color: #0F1013;

  font-size: 14px;
  padding: 9px;
}

.modal-error-text {
  color: rgb(207, 80, 80);
  margin-top: 1.5%;
  font-size: 0.9rem;
}

.manage-subscription {
  color: black;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: rgb(239, 240, 236);
  border-radius: 5px;
  border: none;
}


@media screen and (max-width: 600px) {

  /* .account-values-profiles{
  padding-top: 1.25rem;
  padding-bottom: 0rem;
  padding-right: 2.7rem;
} */

  .account-values-grid {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 320px) {
  .account-value-input {
    border: none;
    width: 250px;
    border: 1px solid #ccc;
  }

}

/* @media screen and (max-width: 400px){

  .account-values-grid {
    grid-template-columns: repeat(auto-fill, 350px);
  }

} */
.changeButton {
  background: black;
  color: white;
  margin-top: 10px;
  display: block;
}
.saveButtonDiv{
  display: flex; 
  justify-content: space-between; 
  width: 60%;
}
.saveButton {
  background: black;
  color: white;
  margin-top: 10px;
  width: 45%;
}

.accountButton {
  background: black;
  color: white;
  margin-top: 10px;
  width: auto;
  height: auto;
}
.errorText{
  color: red;
  text-align: center;
  margin-top: 2%;
}
.nameError{
  color: red;
  font-size: 0.9rem;
}
.mt-8 {
  margin-top: 8;
}
.background-none {
  background: none;
  border: 0;
}
.mb-30 {
  margin-bottom: 30px;
}

.profile-upload{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.profile-upload .ant-image {
  height: 100%;
}

.profile-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 5%;
}