.creator-profile-wrapper {
  text-align: center;
  margin-top: -4%;
  position: sticky;
  margin: auto;
  padding-top: 20px;
}

.creator-profile-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid lightgrey;
}


.creator-profile-img-loading-div {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid lightgrey;
  background-color: rgb(229, 229, 229);
  align-content: center;
}

.creator-profile-name {
  margin-top: .5rem;
  margin-bottom: .5rem;
  margin-left: 5%;
  margin-right: 5%;
  display: block;
  font-size: 1.3rem;
  font-style: normal;
}

/* .creator-profile-description {
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 4%;
  margin-left: 5%;
  margin-right: 5%;
  width:70%;
  margin:auto;
  color:black
} */
.creator-profile-social-links {
  margin-left: 5%;
  margin-right: 5%;
}

/* .creator-profile-social-links button:hover{
  transform: scale(1.05);
}
.creator-profile-social-links a:hover{
  transform: scale(1.05);
} */
.profile-active-socials {
  margin-top: -1.4%;
  margin-bottom: 3%;
}

.profile-active-socials a {
  background-color: #fff;
}

.profile-active-socials-icon {
  height: 20px;
  width: 20px;
  margin: 0.4%;
}

.creator-profile-description {
  font-size: 14px;
  /* padding: 0.5rem 2%; */
  width: 90%;
  margin: .5rem auto .5rem;
  color: black;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .creator-profile-description {
    font-size: 0.7rem;
    /* Font size ko chota karne ke liye */
    line-height: 1.4;
    /* Line spacing ko badhane ke liye */
    margin-bottom: 0.5rem;
    word-wrap: break-word;
    /* Niche ka margin kam karne ke liye */
  }

  /* .creator-profile-social-links button:hover{
    transform: none;
  }
  .creator-profile-social-links a:hover{
    transform: none;
  } */
}

.creator-profile-social-links-search {
  margin-right: 7px;
  padding: 4px;
  background-color: #cddfda !important;
  border-radius: 30px !important;
  height: 35px !important;
  width: 35px !important;
  border: none !important;
}

/* .search-icon-social { */
.color-black {
  color: black;
}

.search-button {
  margin-right: 7px;
  padding: 4px;
  padding-top: 2px;
  border: none;
  border-radius: 30px;
  height: 35px;
  width: 35px;
  color: black;
}

.search-button:focus {
  outline: none;
}

/* .social-icon-style {
 margin-right: 7px;
 padding: 4px;
 border-radius: 30px;
 height: 35px;
 width: 35px; */
.search-link {
  margin-right: 7px;
  padding: 4px;
  border-radius: 30px;
  height: 35px;
  width: 35px;
  -webkit-tap-highlight-color: transparent;
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5rem 5% .5rem;
  margin: auto;
  flex-wrap: wrap;
  row-gap: 5px;
}

.live-menu {
  margin-left: auto;
  margin-right: 12%;
  margin-top: -25px;
  justify-content: flex-end;
}

.grid-display {
  display: grid;
  grid-template-columns: 1fr auto 1fr
}

.trending-products {
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal-center {
  text-align: center;
  padding-bottom: 2%;
}

.mb-2 {
  margin-bottom: 2%;
}

.profile-image {
  position: relative;
}

.edit-profile {
  position: absolute;
  bottom: 0%;
  right: 0%;
  padding: 2px 5px;
  color: black;
  background: #f1f1f6;
  border-radius: 50%;
  cursor: pointer;
}

.edit-name {
  padding: 2px 5px;
  color: rgba(27, 27, 27, 0.518);
  /* background: #f1f1f6; */
  border-radius: 50%;
  cursor: pointer;
}

fieldset.userdashboard-profile {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  margin: 16px auto;
  max-width: 600px;
  position: relative;
}

fieldset.userdashboard-profile legend {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0 8px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  float: none;
  width: auto;
  margin-bottom: 0;
}

.creator-dashboard-profile-description {
  font-size: 14px;
  /* padding: 0.5rem 2%; */
  width: 100%;
  color: black;
  text-align: center;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}