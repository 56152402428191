.copy-clipboard-btn-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(250, 250, 250);
	gap: 10px;
	border-radius: 8px;
	padding: 4px 14px;
	border: dashed rgb(154, 154, 154) 1px;
	cursor: pointer;
}

.tooltip-style {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.m-0{
	margin: 0;
}
