.card-link {
  text-decoration: none; /* Remove default anchor tag underline */
  color: inherit; /* Inherit text color */
}

.creator-card-grid-wrp {
  display: grid;
  justify-content: center;
  grid-gap: 25px;
  overflow: hidden;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 1280px) {
  .creator-card-grid-wrp {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width:600px){
  .creator-card-grid-wrp {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}

.creator-card {
  height: fit-content;
  border: 1px solid #ccc !important;
  border-radius: 8px !important;
  overflow: hidden;
  padding-bottom: .325rem;
  box-shadow: 0 0 0.5px rgba(204, 204, 204, 0.6);
}
.creator-card-meta:hover {
  background-color:#d0d2d5;
  border-radius: 8px;
  overflow: hidden;
  font-weight: bolder;
}
.creator-card-cover {
  position: relative;
}

.creator-card-img{
    width: 100%;
    height: 8rem;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #000;
    height: 8rem;
    width: 100%;
    object-fit: cover;
    object-position: 5% 14%;
  }

.creator-card-img-line{
  width: 50%;
    height: 100%;
    max-height: 300px;
    padding-bottom: .325rem;
}

.card-line{
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
}

.creator-card-dummy-img {
  width: 100%;
  height: 8rem;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
}


.creator-card-meta {
  white-space: normal;
  overflow-wrap: break-word;
  padding: 10px;
  font-size: 16px;
  color: #000;
  background-color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  height: 100%;
  justify-content: center;
    flex-direction: column;
    min-height: 5.25rem;
    padding: .5rem .75rem .5rem;
    font-weight: bolder;

  /* width:20%; */
  /* border:1px solid purple */
}

.h-100 {
  height: 100%;
}

.mt-8 {
  margin-top: 8%;
}