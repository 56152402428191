.loginAuth {
	width: 65%;

	padding-left: 5%;
	padding-top: 3%;
	padding-right: 5%;
}
.providers {
	width: 80%;
	align-self: center;
	height: auto;

	text-align: center;
	margin: 8% auto;
}

.providers h2 {
	font-size: 2.5rem;
	color: #fff;
}
.input-form {
	display: block;
}
.input-div {
	margin: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 2vh;
}

.input-div input {
	width: 100%;
	text-align: center;
	border: 1px solid #404040;
	margin: 2% auto 2% auto;
	background-color: #404040;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px;
}

.input-div.email { 
	margin-bottom: 0px;
}

.submit-btn {
	/* width: auto;
	border: 1px solid #404040;
	background-color: #101010;
	border-radius: 5px;
	color: #ededed;
	font-size: 1rem;
	padding: 5px; */

	color: black;
	padding: 0.5rem 1rem;
	text-decoration: none;
	background-color: rgb(239, 240, 236);
	border-radius: 5px;
	border: none; 
}
.submit-btn:disabled {
	border: 1px solid #fe7171;
}
.auth-reset-password {
	margin-top: 4%;
	font-size: 1rem;
}

.auth-reset-password a {
	background-color: inherit;
	color: #ededed;
}
.login-animation {
    width: 50%;
    /* height: 100vh; */
    background-color: #171717;
    border-left: 1px solid #707070;;
  }
  .auth-right-panel { 
    margin-top: 45%;
    margin-left: 20%;
    margin-right: 10%;
  }
  .auth-creator-quote { 
    font-size: 1.75rem;
    font-weight: 400;
    color: #fff;
  }
  
  .auth-creator-image { 
    width: 75px;
    height: 75px;
    border-radius: 50px;
  }
  
  .auth-creator-name { 
    font-size: 1rem;
    display: table-cell;
    vertical-align: middle;
    padding-left: 5%;
    color: #707070;
  }
  
  .auth-creator-quote-image { 
    font-size: 160px;
  }
  
  .auth-creator-info { 
    display: table; 
    grid-template-columns: 20% 80%;
    margin-top: 15px;
  }
  @media screen and (max-width: 767px) {
	/* .auth-container {
	  flex-direction: column;
	} */

	.input-div {
		width: 100%;
	}
	.loginAuth {
		width: 100%;
	
		padding-left: 5%;
		padding-top: 3%;
		padding-right: 5%;
	}
  
	.login-animation {
	  display: none;
	}
  
	.providers {
	  width: 100%;
	}
  
	/* Add any additional styles for phones here */
  }
  .reset-btn{
	border:none;
	background: none;
	color: white;
	text-decoration: underline;
}